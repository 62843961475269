const initialState = {};

const loadLiterals = literals => ({
  type: 'LOAD_LITERALS',
  payload: literals,
  currentLang: literals.currentLang || 'en'
});

const Literals = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_LITERALS':
      return {
        ...action.payload,
        currentLang: action.currentLang
      };
    default:
      return state;
  }
};

export { Literals as default, loadLiterals };

import React from 'react';
import '../../Assets/css/Components/BannerComponent.scss';
import Cookie from '../../Utils/Cookie';

class BannerComponent extends React.Component {
  render() {
    return (
      <section className="Banner">
        <p>
          {this.props.Literals.text.claimNotCompleted.label}
          <strong>{Cookie.read('pendingClaim')}</strong>
        </p>
        <nav>
          <button
            id="gh-complete-claim-now"
            className="Navigation-CenterButton Button-hollow"
            onClick={(e) => this.props.gotoStep('CONTINUE_CLAIM', 'previous')}
          >
            <i className="icon icon-resume-claim"></i>
            {this.props.Literals.text.claimNotCompleted.continue}
          </button>
        </nav>
        <button className="Banner-close-button" onClick={this.props.closeBanner}>
          <i className="icon icon-cross-full"></i>
        </button>
      </section>
    );
  }
}

export default BannerComponent;

/**
 * React Native webapp main entry point
 */
// SYSTEM import
import Config from './Config/Env';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';

// Import internal libraries
import { loadLiterals } from './Store/Literals';
import reducer from './Store';
import loadLang from './i18n';

// Components import
import Form from './Components/Form/Form.js';
import Header from './Components/Header/Header.js';
import Navigation from './Components/Navigation/Navigation.js';

import FormUtil from './Utils/Form.js';
import Cookie from './Utils/Cookie.js';

// SCSS import (thanks to webpack, it's compiled automatically)
import './index.scss';

const cookies = Cookie.readAll();
window.Config = Config;

const OAuthToken = jwt.decode(cookies.OauthToken);
// OAuthToken.exp is a timestamp and Date.now() is a millitimestamp
if (!OAuthToken || parseInt(OAuthToken.exp) - (Date.now()/1000 + 300) <= 0) {
  Cookie.erase('OauthToken');
  Cookie.erase('Driver');
  Cookie.erase('pendinClaim');
  window.location.href = window.Config.uberTokenApi;
}

// If the OauthToken is not defined, releoad the app to authenticate the user
if (!cookies || typeof cookies === 'undefined' || typeof cookies.OauthToken === 'undefined') {
  const url = `${window.Config.uberTokenApi}`;
  window.location.href = url;
} else {
  try {
    // populate the window.Config namespace
    window.Config = {
      ...window.Config,
      ...cookies,
    };

    //DeleteCookie();

    // Update the value of the Driver with it's decoded part
    window.Config.Driver = JSON.parse(atob(window.Config.Driver));
    const p = window.Config.Driver.p;
    if (p && (window.localStorage.getItem('fromStep') === null || window.localStorage.getItem('fromStep') !== p)) {
      window.localStorage.setItem('fromStep', p);
      window.location.href = `${encodeURIComponent(window.location.origin)}?p=${encodeURIComponent(p)}`;
    }
    window.Config.Driver.rating = parseFloat(window.Config.Driver.rating || 3.0);

    // Create the redux store thanks to the reducers
    const store = createStore(reducer);

    // Load the locale system (store Literals)
    const lang = loadLang();
    store.dispatch(loadLiterals(lang));

    // Check the viability of the system : if the session is not valid
    // (as the OauthToken is pass away), reload the session
    /**
     * checkSessionViability
     * @description if cookies (window.Cookies()) driver or OauthToken is not valid, releoad the session
     * @param none
     * @return none
     */
    function checkSessionViability() {
      if (!window.Config.OauthToken || !window.Config.Driver) {
        const previousStep = window.state.previousStep ? `?p=${window.state.previousStep}` : '';
        const url = `${window.Config.uberTokenApi}${previousStep}`;
        window.location.href = url;
      }
      window.requestAnimationFrame(checkSessionViability);
    }
    // Check session viability
    window.requestAnimationFrame(checkSessionViability);

    window.popState = (e) => {
      const formState = store.getState().Form;
      const currentStep = formState.currentStep;
      if (FormUtil.steps[currentStep].previous && formState.navigationStuck === false) {
        store.dispatch({
          type: 'gotoStep',
          currentStep: FormUtil.steps[currentStep].previous.step,
        });
      }
      return false;
    };

    const formState = store.getState().Form;
    window.history.pushState({ currentStep: formState.currentStep }, formState.currentStep, window.location.search);

    window.addEventListener('popstate', window.popState, false);
    // Start the react renderer
    ReactDOM.hydrate(
      <Provider store={store}>
        <Header />
        <Form />
        <Navigation />
      </Provider>,
      document.getElementById('root')
    );
  } catch (e) {
    // If there is an error (Driver is not decoded properly for example...)
    //display an error,
    // then relaod the session
    //window.alert(e);
    //window.location.href = window.Config.uberTokenApi;
  }
}

import Form from './Form';
import Steps from '../Components/Steps/index.js';
import Common from './Common';
import moment from 'moment-timezone';
import UkModulusChecking from 'uk-modulus-checking';

moment.tz.setDefault(moment.tz.guess());
const ns = {
  accountNumber: null,
  sortCode: null,
};

const Navigation = {
  getPage: () => {
    const parameters = Common.getURLParameters();
    // How to get the page value ?
    // 1 : check if there is a manually filled query string parameter p or...
    // 2 : check if there is an already filled parameter in sessionStorage currentStep or...
    // 3 : use the default one in (/Utils/Form/Form.js).currentStep
    let step = parameters.p ? parameters.p : Form.currentStep;
    // if the query string parameter is fill BUT it's not linked with a real Form.step, return a 404 error
    if (typeof Steps[step] === 'undefined') {
      step = 'ERROR404';
    }
    return step;
  },

  gotoStep: ({ state, action }) => {
    const { currentStep } = action;
    if (!!window.gmap) {
      window.gmap = undefined;
    }
    window.history.pushState({ currentStep }, '', '/');
    const updatedState = {
      ...state,
      currentStep,
      ...action,
    };
    if (action.way === 'previous') {
      updatedState.navigationStuck = false;
    }
    if (action.way === 'next') {
      updatedState[state.currentStep] = {
        validated: true,
      };
    }
    return updatedState;
  },

  emptyCurrentFilesAndGotoStep: ({ state, action }) => {
    const { currentStep } = action;
    window.Config.filesToUpload[currentStep] = [];
    const updatedState = {
      ...state,
      currentStep,
      ...action,
    };
    if (action.way === 'previous') {
      updatedState.navigationStuck = false;
    }
    return updatedState;
  },

  validateField: ({ state, action }) => {
    const error = checkField(action.field);

    const updatedState = {
      ...state,
      errors: {
        ...state.errors,
        [state.currentStep]: {
          ...state.errors[state.currentStep],
          ...error,
        },
      },
    };
    return updatedState;
  },
  validateStep: ({ state }) => {
    const nextStep = Steps[state.currentStep].next.step;
    window.history.pushState({ currentStep: nextStep }, '', '/');
    const { existingErrors, errors } = validateForm(state);
    if (existingErrors === false) {
      if (document.querySelector(`[name="dateOfTheIncident-date"]`)) {
        const date = document.querySelector(`[name="dateOfTheIncident-date"]`).value;
        const time = document.querySelector(`[name="dateOfTheIncident-time"]`).value;
        const offset = moment().format('Z');
        state.data.incidentDate = `${date}T${time}:00${offset}`;
      }

      const currentStep = typeof nextStep === 'function' ? nextStep(state.data) : nextStep;
      const updatedState = {
        ...state,
        errors: {},
        data: {
          ...state.data,
          ...gatherData(state),
        },
        currentStep,
        [state.currentStep]: {
          validated: true,
          ...gatherData(state),
        },
      };
      return updatedState;
    }
    return {
      ...state,
      errors,
    };
  },
  getErrorsForTheCurrentStep(currentStep) {
    let errors = {};
    const formFields = [...document.querySelectorAll(`[data-form="Form-${currentStep}"]`)];
    formFields.forEach((domElement) => {
      const name = `${domElement.tagName}_${domElement.type.replace('-', '')}`;
      const error = FieldController[name]('errors', domElement);
      if (error)
        errors = {
          ...errors,
          [currentStep]: { ...error, ...errors[currentStep] },
        };
    });
    return errors;
  },
  stepIsProperlyFilled(currentStep, state) {
    //console.log(state.errors[currentStep]);
    let existingErrors = false;
    let errors = [];

    if (typeof state === 'undefined') {
      const query = `[data-form="Form-${currentStep}"]`;
      const fields = [...document.querySelectorAll(query)];
      errors = fields.map((e) => checkForErrors(e));
      existingErrors = errors.some((item) => {
        const [field] = Object.entries(item);
        const [, error] = field;

        return error && error.length > 0;
      });
    }
    if (!existingErrors && !!state) {
      errors = state.errors[currentStep] || [];
      existingErrors = Object.entries(errors).some(([field, info]) => {
        return info.length > 0;
      });
    }
    return existingErrors === false;
  },
  isThereSomeErrors(errors) {
    return Object.keys(errors).some((index) => errors[index].length > 0);
  },
};

function validateForm(state) {
  let errors = {};
  if (state.currentStep === 'FILES') {
    errors = {
      [state.currentStep]: {
        ...checkFiles(state.data, Form.filesDescriptors),
        ...errors[state.currentStep],
      },
    };
  } else {
    errors = Navigation.getErrorsForTheCurrentStep(state.currentStep);
  }
  console.log('Errors ?', errors);
  let existingErrors = (errors[state.currentStep] && Navigation.isThereSomeErrors(errors[state.currentStep])) || false;
  if (existingErrors === true) {
    const firstLeben = Object.keys(errors[state.currentStep]).filter((key) => {
      return errors[state.currentStep][key].length > 0;
    });
    const query = `label[for="gh-${firstLeben[firstLeben.length - 1]}"]`;
    const element = document.querySelector(query);
    if (element) {
      element.scrollIntoView(true);
    }
  }
  // If everything goes well
  return { existingErrors, errors };
}

const FieldController = {
  INPUT(status, element) {
    return status === 'errors'
      ? checkForErrors(element)
      : {
          [element.name]: checkBooleanValue(element.value, element.type),
        };
  },
  INPUT_file(status, element) {
    const name = element.getAttribute('data-file');
    if (
      status === 'errors' &&
      element.files.length === 0 &&
      Form.filesDescriptors[name].required &&
      Form.filesDescriptors[name].required === true
    ) {
      return {
        [name]: [{ type: 'mandatory' }],
      };
    }
    if (status === 'data' && element.files.length > 0) {
      const domFiles = [...document.querySelectorAll(`[data-file="${name}"]`)];
      const data = [];
      for (let i = 0; i < domFiles.length; i++) {
        data.push({
          documentId: domFiles[i].files[0].name,
          documentIndex: i,
        });
      }
      return {
        [name]: data,
      };
    }
  },
  INPUT_tel(status, element) {
    const { name, value, required, pattern } = element;
    //console.log('Téléphone', name, value, required, pattern);
    const regexp = new RegExp(pattern);
    let response = {
      [name]: [],
    };
    if (pattern && !value.match(regexp) && status === 'errors') {
      response[name].push({ type: 'format' });
    }
    if (value.length === 0 && required && status === 'errors') {
      response[name].push({ type: 'mandatory' });
    }
    if (status === 'data') {
      response[name] = checkBooleanValue(element.value, 'tel');
    }
    return response;
  },
  INPUT_email(status, element) {
    const { name, value, required, pattern } = element;
    const regexp = new RegExp(pattern);
    let response = { [name]: [] };
    //console.log(name, pattern, value, value.match(regexp));
    if (pattern && !value.match(regexp) && status === 'errors') {
      response = { [name]: [{ type: 'format' }] };
    }
    if (value.length === 0 && required && status === 'errors') {
      response = {
        [name]: [{ type: 'mandatory' }],
      };
    }
    if (status === 'data') {
      response = {
        [name]: checkBooleanValue(element.value, 'email'),
      };
    }
    //console.log('INPUT email result ', response);
    return response;
  },
  INPUT_text(status, element) {
    if (element.getAttribute('rel') !== null) {
      const type = element.getAttribute('rel').toUpperCase();
      return FieldController[type](status, element);
    }
    return FieldController.INPUT(status, element);
  },
  INPUT_time(status, element) {
    const time = FieldController.INPUT(status, element);
    return time;
  },
  INPUT_date(status, element) {
    const date = FieldController.INPUT(status, element);
    return date;
  },
  INPUT_radio(status, element) {
    const radioElement = document.querySelector(`[name="${element.name}"]:checked`);

    let response = [];
    if (radioElement === null && element.required) response = [{ type: 'mandatory' }];
    if (status === 'errors') {
      return { [element.name]: response };
    }
    return {
      [element.name]: radioElement ? checkBooleanValue(radioElement.value, 'radio') : false,
    };
  },
  INPUT_hidden(status, element) {
    return FieldController.INPUT(status, element);
  },
  TEXTAREA_textarea(status, element) {
    //console.log('Textarea > ', element);
    return FieldController.INPUT(status, element);
  },
  SELECT_selectone(status, element) {
    const value = element.options[element.selectedIndex].value;
    //console.log(element.name, '>', value);
    if (status === 'errors') {
      if (value === 'undefined' && element.required) {
        return {
          [element.name]: [{ type: 'mandatory' }],
        };
      } else {
        return {
          [element.name]: [],
        };
      }
    }
    return {
      [element.name]: checkBooleanValue(value, 'selectone'),
    };
  },
  MODULUS(status, element) {
    if (status === 'errors') {
      if (element.getAttribute('name') === 'recipientAccountNumber') {
        ns.accountNumber = element.value;
      }
      if (element.getAttribute('name') === 'recipientSortCode') {
        ns.sortCode = element.value;
      }

      if (element.value.length === 0) {
        return { [element.name]: [{ type: 'mandatory' }] };
      }

      if (ns.accountNumber && ns.sortCode) {
        const result = new UkModulusChecking({
          accountNumber: ns.accountNumber,
          sortCode: ns.sortCode,
        });
        const isValid = result.isValid();
        if (isValid === false) {
          return { [element.name]: [{ type: 'format' }] };
        }
        return { [element.name]: [] };
      }
      return { [element.name]: [] };
    } else {
      return {
        [element.name]: checkBooleanValue(element.value, element.type),
      };
    }
  },
};

/**
 * gather all the form items from a specific Form, and return the list of errors messages to be display below each form items
 * Each fields inside this form should have a data-form attribute with the same formName
 * @param {String} formName Form Name
 * @return {Array} Return an array of errors in the form : { domField: <domElement>, message: <String> }
 */
function checkData(formName) {
  const formItems = document.querySelectorAll(`[data-form="${formName}"]`);
  let errors = {};
  for (let field of formItems) {
    errors = {
      ...errors,

      [field.name]: [...checkField(field)],
    };
  }
  return errors;
}

function checkField(field) {
  const fieldType = `${field.tagName}_${field.type.replace('-', '')}`;
  //console.log('Checking ', field.name, fieldType);
  return FieldController[fieldType]('errors', field);
}

function checkFiles() {
  let errors = {};
  const mandatoryFiles = Object.keys(window.Config.filesToUploadProperties).filter(
    (k) => window.Config.filesToUploadProperties[k].mandatory
  );
  const mandatoryFilesThatAreNowFullfilled = mandatoryFiles.filter((fileName) => {
    return window.Config.filesToUpload[fileName].length === 0;
  });
  if (mandatoryFilesThatAreNowFullfilled.length > 0) {
    mandatoryFilesThatAreNowFullfilled.forEach((fileName) => {
      errors = {
        ...errors,
        [fileName]: [{ type: 'mandatory' }],
      };
    });
  }
  return errors;
}

function gatherData(props) {
  const formName = `Form-${props.currentStep}`;
  const formItems = document.querySelectorAll(`[data-form="${formName}"]`);
  let data = {};
  for (let field of formItems) {
    const fieldType = `${field.tagName}_${field.type.replace('-', '')}`;
    const result = FieldController[fieldType]('data', field, props);
    data = {
      ...data,
      ...result,
    };
  }
  if (props.currentStep === 'WHERE') {
    data = {
      ...props.data,
    };
  }
  return data;
}

function checkBooleanValue(value, type) {
  if (type === 'radio' && (value === 'false' || value === 'true')) {
    if (value === 'true') value = true;
    if (value === 'false') value = false;
  }
  return value;
}

function checkForErrors(element) {
  let status = [];
  if (element.getAttribute('size')) {
    const limit = parseInt(element.getAttribute('size'));
    if (element.value.length > limit) {
      status.push({ type: 'size', value: element.value.length, limit });
    }
  }

  if ((element.required && element.value.length === 0) || element.value === 'undefined') {
    status.push({ type: 'mandatory' });
  }
  if (element.getAttribute('pattern') && element.value.length > 0) {
    const regExp = new RegExp(element.getAttribute('pattern'));
    if (element.value.match(regExp) === null) {
      status.push({ type: 'format' });
    }
  }

  return {
    [element.name]: status,
  };
}

export default Navigation;
export { checkData, gatherData, FieldController, validateForm, checkForErrors };

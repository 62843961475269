/**
 * @file /src/Store/Form.js
 * @description File used to manage the Form store
 */
import moment from 'moment-timezone';

import Steps from '../Components/Steps';
import Where from '../Utils/Where';
import What from '../Utils/What';
import Navigation, { gatherData } from '../Utils/Navigation';
import Docs from './DocumentsRules';
import initialState from '../Utils/Form';
import Cookie from '../Utils/Cookie';

moment.tz.setDefault(moment.tz.guess());

/**
 * gatherDataFromSessionStorage
 * @param none
 * @return either the initialState (if no sessionStorate.state is initialized) of the sessionStorage.state value
 */
const gatherDataFromSessionStorage = () => {
  const savedState =
    window.sessionStorage.getItem('state') !== null && window.sessionStorage.getItem('state') !== 'undefined'
      ? JSON.parse(window.sessionStorage.getItem('state'))
      : null;
  return savedState ? savedState : initialState;
};

/**
 * Form
 * @param {Object} state The current state of the store
 * @param {Object} action Literal object used to store objects value from a dispatch call
 */
const Form = (state = initialState, action) => {
  state = gatherDataFromSessionStorage();
  let savedSteps = window.sessionStorage.getItem('savedSteps') || '{}';
  savedSteps = JSON.parse(savedSteps);
  //console.log('>>> data', state.data, action);
  const pendingClaim = !!Cookie.read('pendingClaim');
  if (pendingClaim && state.currentStep && action.currentStep && action.currentStep !== state.currentStep) {
    state.continueClaimNotification = true;
    window.sessionStorage.setItem('notNow', 'false');
  }
  if (state.continueClaimNotification && !pendingClaim) {
    state.continueClaimNotification = false;
  }
  // Avoid potential issue due to state.currentStep that is not set (?????)
  if (!state.currentStep) {
    window.sessionStorage.clear();
    window.location.reload();
  }
  // initialize the state with the current sessionStorage.state value or the initial state if it's not set yet.
  let updatedState = null;
  state.previousStep = state.currentStep;

  // Emptying savedSteps
  switch (state.currentStep) {
    case 'PORTAL':
    case 'DISCLAIMER':
    case 'FAQ':
    case 'CONCLUSION':
    case 'LOAD_CLAIM':
    case 'CONTINUE_CLAIM':
    case 'NOT_COVERED':
    case 'PRIVACY_POLICY':
    case 'ERROR404':
      window.sessionStorage.removeItem('savedSteps');
      break;
    default:
      break;
  }

  switch (action.type) {
    // Re-initilization the filesToUpload (prevent empty input[type="file"] and files stored in state)
    case 'initForm':
      let currentStep = action.currentStep;
      updatedState = {
        ...state,
        errors: {},
        error: {},
        data: {},
        continueClaimNotification:
          state.steps && state.steps[currentStep].stepIndex === 1 && window.localStorage.getItem('pendingClaim')
            ? true
            : false,
        navigationStuck: false,
        currentStep,
      };
      if (savedSteps && savedSteps.currentStep) {
        updatedState = {
          ...updatedState,
          data: savedSteps,
          currentStep: savedSteps.currentStep,
        };
      }
      window.Config.filesToUpload = {};
      window.Config.filesToUploadProperties = {};
      break;
    case 'throwError':
      updatedState = {
        ...state,
        currentStep: 'ERROR',
        error: {
          ...action,
        },
      };
      console.log(updatedState);
      break;
    case 'hideBanner':
      updatedState = {
        ...state,
        continueClaimNotification: false,
      };
      break;
    case 'checkForErrors':
      updatedState = {
        ...state,
        navigationStuck:
          state.errors[state.currentStep] &&
          Object.keys(state.errors[state.currentStep]).some((item) => item.length > 0),
      };
      break;
    // Save form data from data-form attributes
    case 'saveForm':
      updatedState = Navigation.gatherData(action.formName);
      break;
    case 'saveProp':
      updatedState = Navigation.sendFinalForm(state, action);
      break;
    case 'stuckNavigation':
      updatedState = {
        ...state,
        navigationStuck: true,
      };
      break;
    case 'unStuckNavigation':
      updatedState = {
        ...state,
        navigationStuck: false,
        loadingNavigation: false,
      };
      break;
    case 'stuckGoBack':
      updatedState = {
        ...state,
        goBackStuck: true,
      };
      break;
    case 'unStuckGoBack':
      updatedState = {
        ...state,
        goBackStuck: false,
        loadingNavigation: false,
      };
      break;
    case 'concludeCustomerJourney':
      updatedState = {
        ...state,
      };
      break;
    // Update state with flag status
    case 'IncidentIsAnAccident':
      updatedState = What.isAnAccident(action.status, {
        state,
      });
      break;
    // Add errors on form
    case 'formInputError':
      updatedState = What.handleFormErrors(true, {
        state,
        action,
      });
      break;
    // Add errors on form
    case 'formInputValid':
      updatedState = What.handleFormErrors(false, {
        state,
        action,
      });
      break;
    case 'validateField':
      updatedState = Navigation.validateField({
        state,
        action,
      });
      break;
    // Go to specific step without saving or checking errors
    case 'gotoStep':
      updatedState = Navigation.gotoStep({
        state,
        action,
      });
      saveStep(state, action.currentStep);
      break;
    // Go to next step with validation of the form (errors and save to sessionStorage)
    case 'nextStep':
      updatedState = Navigation.validateStep({
        state,
        action,
      });
      saveStep(state);
      break;
    case 'gotoStepUpload':
      updatedState = Navigation.gotoStep({
        state,
        action: {
          currentStep: 'UPLOAD',
          name: action.name,
        },
      });
      if (action.mandatory)
        updatedState = {
          ...updatedState,
          [`File_${action.name}`]: 'mandatory',
        };
      break;
    case 'emptyCurrentFilesAndGotoStep':
      if (window.Config.filesToUpload[state.currentFileName]) {
        window.Config.filesToUpload[state.currentFileName] = window.Config.filesToUpload[state.currentFileName].filter(
          (f) => f.saved === true
        );
      }
      updatedState = {
        ...state,
        currentStep: action.currentStep,
        ...action,
      };
      if (action.way === 'previous') {
        updatedState.navigationStuck = false;
      }
      break;
    case 'saveFilesAndGotoStep':
      if (window.Config.filesToUpload[state.currentFileName]) {
        window.Config.filesToUpload[state.currentFileName].forEach((f) => {
          f.saved = true;
        });
      }
      updatedState = {
        ...state,
        currentStep: action.currentStep,
        ...action,
      };
      if (action.way === 'previous') {
        updatedState.navigationStuck = false;
      }
      break;
    case 'setCurrentFileName':
      updatedState = {
        ...state,
        currentFileName: action.fileName,
      };
      break;
    case 'completeForm':
      updatedState = Navigation.completeForm({
        state,
        action,
      });
      break;
    // GoogleMap - Save place
    case 'UpdatePlaces':
      updatedState = Where.updatePlaces({
        state,
        action,
      });
      updatedState = {
        ...updatedState,
        error: {},
      };
      saveStep(state);
      break;
    case 'NoLocationFound':
      updatedState = {
        ...state,
        error: { message: 'NoLocationFound' },
      };
      break;
    case 'CantUpdatePlacesFromNavigator':
      updatedState = Where.cantUpdatePlacesFromNavigator({
        state,
        action,
      });
      break;
    case 'loadingPlace':
      updatedState = {
        error: {},
        ...state,
        loadingPlace: true,
      };
      break;
    case 'placeLoaded':
      updatedState = {
        ...state,
        loadingPlace: false,
      };
      break;
    // GoogleMap - Update place and center on Gmap
    case 'PlaceChanged':
      /*
      updatedState = Where.centerOnMap({
        state,
        action
      });
      */
      break;
    case 'eraseLocation':
      updatedState = Where.erasePlace({
        state,
        action,
      });
      break;
    // GoogleMap - Event / manage long click
    case 'FingerUp':
      const FingerUp = Where.handleLongClick('Up', {
        state,
        action,
      });
      updatedState = FingerUp ? FingerUp : state;
      saveStep(state);
      break;
    // GoogleMap - Event / manage long click
    case 'FingerDown':
      const FingerDown = Where.handleLongClick('Down', {
        state,
        action,
      });
      updatedState = FingerDown ? FingerDown : state;
      break;
    // GoogleMap - Event / manage long click
    case 'DragStart':
      updatedState = Where.handleDraging('start', {
        state,
        action,
      });
      break;
    // GoogleMap - Event / manage long click
    case 'DragEnd':
      updatedState = Where.handleDraging('end', {
        state,
        action,
      });
      break;
    case 'ZoomChanged':
      updatedState = Where.handleZoomChanged({
        state,
        action,
      });
      break;
    case 'MouseMove':
      updatedState = Where.handleMouseMove({
        state,
        action,
      });
      break;
    case 'unloadMap':
      updatedState = Where.handleUnloadMap({ state, action });
      break;
    // Not used : check licence plate before validation
    case 'checkLicencePlate':
      updatedState = What.checkLicenPlate({
        state,
        action,
      });
      break;
    case 'notCovered':
      updatedState = {
        ...state,
        currentStep: 'NOT_COVERED',
        errors: {
          claimRejectionReasons: action.claim.claimRejectionReasons,
        },
      };
      break;
    // Submit Claim
    case 'SubmitedClaim':
      /** re-initialize data to be able to create an another claim without saved data */
      state.data = {};
      window.Config.filesToUpload = {};
      window.sessionStorage.removeItem('savedSteps');
      /**
       * When a claim is submited, we check if the coverageCheckIndicator (Boolean) variable is equal to false
       */
      if (action.data.coverageCheckIndicator === false) {
        state.currentStep = 'NOT_COVERED';
        updatedState = {
          ...state,
          errors: {
            claimRejectionReasons: action.data.claimRejectionReasons,
          },
        };
      } else {
        /**
         * ... if coverageCheckIndicator is true, then we display the VALIDATION step
         * and set the claim attribute to the state, to be able to use on the next step
         */
        state.currentStep = 'VALIDATION';
        updatedState = {
          ...state,
          claim: action.data,
        };
      }
      break;
    case 'FormatAddressFromAPI':
      state.claim.policyholder.postalAddress = action.postalAddress;
      updatedState = {
        ...state,
      };
      break;
    // Handle error on submited Claim
    case 'ErrorOnSubmitedClaim':
      state.currentStep = 'ERROR';
      /** reinitialize data */
      state.data = {};
      window.Config.filesToUpload = {};
      window.sessionStorage.removeItem('savedSteps');
      updatedState = {
        ...state,
        error: {
          code: action.type,
          message: action.claimRejectionReasons,
        },
      };
      break;
    // Submit Claim
    case 'ClaimComplete':
      /** reinitialize data */
      state.data = {};
      window.Config.filesToUpload = {};
      window.sessionStorage.removeItem('savedSteps');
      /**
       * When a claim is submited, we check if the coverageCheckIndicator (Boolean) variable is equal to false
       */
      if (action.data.coverageCheckIndicator === false) {
        state.currentStep = 'NOT_COVERED';
        updatedState = {
          ...state,
          errors: {
            claimRejectionReasons: action.data.claimRejectionReasons,
          },
        };
      } else {
        /**
         * ... if coverageCheckIndicator is true, then we display the VALIDATION step
         * and set the claim attribute to the state, to be able to use on the next step
         */
        state.currentStep = 'CONCLUSION';
        updatedState = {
          ...state,
        };
      }
      break;
    // Handle add file to the current filesToUpload literal object (used to store files while they are sent to post/claim-complete)
    case 'displayThumbnail':
      updatedState = {
        ...state,
        filesToUpload: action.filesToUpload,
      };
      break;
    case 'stuckUpload':
      updatedState = {
        ...state,
        stuckUpload: true,
      };
      break;
    case 'unStuckUpload':
      updatedState = {
        ...state,
        stuckUpload: false,
      };
      break;
    case 'loadFile':
      updatedState = {
        ...state,
        loadFile: action.status,
      };
      break;
    // Handle removing file from the filesToUpload literal object
    case 'removeFile':
      updatedState = state;
      window.Config.filesToUpload[action.name].splice(action.index, 1);
      const ghostFileIndex = `${action.name}_${action.index}`;
      if (document.getElementById(ghostFileIndex)) {
        document.querySelector('body').removeChild(document.getElementById(ghostFileIndex));
      }
      break;
    case 'TriggerDisplayDriverToRepairIndicator':
      updatedState = {
        ...state,
        data: {
          ...state.data,
          totalLossIndicator: action.totalLossIndicator,
        },
        visibleFiles: [],
        visibleDates: [],
        navigationStuck: !action.totalLossIndicator,
      };

      Object.entries(updatedState.filesDescriptors).forEach(([fieldName, properties]) => {
        properties.visible = false;
      });

      if (action.totalLossIndicator === false) {
        const driverToRepairIndicator =
          updatedState.data.driverToRepairIndicator !== 'undefined' ? updatedState.data.driverToRepairIndicator : false;
        const incidentSubType = updatedState.data.incidentSubType ? updatedState.data.updatedState : false;
        const startedRepairIndicator = updatedState.data.startedRepairIndicator
          ? updatedState.data.startedRepairIndicator
          : false;

        if (typeof driverToRepairIndicator !== 'undefined') {
          const code1 = Number(incidentSubType);
          const code2 = Number(startedRepairIndicator);
          const code3 = Number(driverToRepairIndicator);

          const triggerCode = `${code1}${code2}${code3}`;
          Object.entries(updatedState.filesDescriptors).forEach(([fieldName, properties]) => {
            if (typeof properties.required !== 'undefined') {
              properties.required = false;
            }
          });
          // For the current rules based on its trigger code (000 to 111 where each rank is a bit)
          Docs.rules[triggerCode].forEach((key) => {
            if (updatedState.filesDescriptors[key]) {
              updatedState.visibleFiles.push(key);
              if (typeof updatedState.filesDescriptors[key].required !== 'undefined')
                updatedState.filesDescriptors[key].required = true;
            }
            if (updatedState.datesDescriptors[key]) {
              updatedState.visibleDates.push(key);
            }
          });

          if (
            updatedState.data.driverToRepairIndicator === true &&
            updatedState.data.startedRepairIndicator === false
          ) {
            updatedState.navigationStuck = true;
          }
          if (
            updatedState.data.driverToRepairIndicator === false ||
            updatedState.data.startedRepairIndicator === true
          ) {
            updatedState.navigationStuck = false;
          }
        }
      } else {
        // total loss true
        Docs.rules['002'].forEach((key) => {
          if (updatedState.filesDescriptors[key]) {
            if (!updatedState.visibleFiles.includes(key)) updatedState.visibleFiles.push(key);
            if (typeof updatedState.filesDescriptors[key].required !== 'undefined')
              updatedState.filesDescriptors[key].required = true;
          }
        });
      }

      break;
    // handle the trigger to display dates
    case 'TriggerDisplayDate':
      // Reset currentState with empty visibleFiles and visibleDates flags
      updatedState = {
        ...state,
        triggerCode: action.triggerCode,
        visibleFiles: [],
        visibleDates: [],
        data: {
          ...state.data,
          startedRepairIndicator: false,
        },
      };

      // If driverToRepairIndicator then we update the data driverToRepairIndicator to its current value
      if (typeof action.driverToRepairIndicator !== 'undefined') {
        updatedState.data.driverToRepairIndicator = action.driverToRepairIndicator;
      }

      // If startedRepairIndicator is set to a value higher than -1 (which is the default value)...
      if (action.startedRepairIndicator !== -1) {
        // ... we update its data value to the given value
        updatedState.data.startedRepairIndicator = action.startedRepairIndicator;
        // Check the fields required by the current rules from the user selection
        for (let key in updatedState.filesDescriptors) {
          // key is a fileName (present on the dom if you looking at key value as a dom[name])
          // If the file is configured NOT required
          if (typeof updatedState.filesDescriptors[key].required !== 'undefined')
            updatedState.filesDescriptors[key].required = false;
        }
        // For the current rules based on its trigger code (000 to 111 where each rank is a bit)
        //console.log('Action', action.triggerCode);
        Docs.rules[action.triggerCode].forEach((key) => {
          // For each filesDescriptor field, we check if it's present for the current filesDescriptor
          if (updatedState.filesDescriptors[key]) {
            // We put the current key (fileName) to the visible files
            updatedState.visibleFiles.push(key);
            // If the current key is required, then we put it at required true
            if (typeof updatedState.filesDescriptors[key].required !== 'undefined')
              updatedState.filesDescriptors[key].required = true;
          }
          // For each date descriptor, we set the current key
          if (updatedState.datesDescriptors[key]) {
            updatedState.visibleDates.push(key);
          }
        });
      }

      //console.log('TriggerDisplayDate', updatedState.filesDescriptors);

      if (updatedState.data.driverToRepairIndicator === true && updatedState.data.startedRepairIndicator === false) {
        updatedState.navigationStuck = true;
      }
      if (updatedState.data.driverToRepairIndicator === false || updatedState.data.startedRepairIndicator === true) {
        updatedState.navigationStuck = false;
      }

      break;
    // send error
    case 'sendError':
      // Set error with its name
      const errors = {};
      errors[action.errorName] = action.errorName;
      updatedState = {
        ...state,
        errors,
      };
      break;
    /**
     * display errors message below a specific field :
     * errors: {
     *   <step-id>: [{ type: '<i18n-error-name>' }]
     * }
     */
    case 'sendErrors':
      updatedState = {
        ...state,
        errors: action.errors,
      };
      break;
    case 'riseError':
      updatedState = {
        ...state,
        errors: action.errors,
      };
      break;
    // Remove Error From the current step (allow navigation for customer)
    case 'removeError':
      updatedState = {
        ...state,
        errors: {
          ...state.errors,
          [state.currentStep]: {},
        },
        navigationStuck: false,
      };
      break;
    case 'notFound':
      state.currentStep = 'ERROR404';
      updatedState = {
        ...state,
        error: action.error,
        navigationStuck: false,
        loadingNavigation: false,
      };
      break;
    case 'networkError':
      state.currentStep = 'ERROR';
      updatedState = {
        ...state,
        error: action.error,
        navigationStuck: false,
        loadingNavigation: false,
      };
      break;
    case 'allowNavigation':
      updatedState = {
        ...state,
        loadingNavigation: false,
      };
      break;
    case 'loadingNavigation':
      updatedState = {
        ...state,
        loadingNavigation: true,
      };
      break;
    case 'cantUpdatePlacesFromNavigator':
      updatedState = Where.cantUpdatePlacesFromNavigator(state, action);
      break;
    case 'SaveDriverAppStatus':
      updatedState = state;
      updatedState.data[action.name] = action.selectedOption.value;
      break;
    case 'upload':
      PostFiles(state);
      updatedState = state;
      break;
    case 'ClaimLoaded':
      updatedState = {
        ...state,
        claim: action.claim,
        errors: {},
        error: {},
        navigationStuck: false,
        currentStep: 'DETAILS',
      };
      break;
    case 'validateAccountAndSortCode':
      updatedState = {
        ...state,
      };
      if (action.isValid === false) {
        updatedState.errors = {
          ...updatedState.errors,
          [state.currentStep]: {
            recipientAccountNumber: [{ type: 'format' }],
            recipientSortCode: [{ type: 'format' }],
          },
        };
      } else {
        updatedState.errors = {
          ...updatedState.errors,
          [state.currentStep]: {
            recipientAccountNumber: [],
            recipientSortCode: [],
          },
        };
      }
      break;
    case 'plateNumberValidation':
      //console.log('plate number validation', action);
      updatedState = {
        ...state,
        errors: {
          ...state.errors,
          [state.currentStep]: {
            [action.field.name]: [...action.errors],
          },
        },
        navigationStuck: action.errors.length > 0,
      };
      break;
    case 'removeVehicleRepairProof':
      //console.log('After wipeout removeVehicleRepairProof', state.data);
      delete window.Config.filesToUpload.vehicleRepairProof;
      delete window.Config.filesToUploadProperties.vehicleRepairProof;
      delete state.data.repairStartDate;
      delete state.data.repairEndDate;
      updatedState = {
        ...state,
      };
      //console.log('After wipeout removeVehicleRepairProof', updatedState.data);
      break;
    case 'removeVehicleReplacementProof':
      //console.log('Before wipeout removeVehicleReplacementProof', state.data);
      delete window.Config.filesToUpload.vehicleReplacementProof;
      delete window.Config.filesToUploadProperties.vehicleReplacementProof;
      delete state.data.vehicleReturnToPlatformDate;
      delete state.data.vehicleUnavailabilityStartDate;
      updatedState = {
        ...state,
      };
      break;
    case 'removeTotalLossProof':
      //console.log('Before wipeout removeVehicleReplacementProof', state.data);
      delete window.Config.filesToUpload.totalLossProof;
      delete window.Config.filesToUploadProperties.totalLossProof;
      updatedState = {
        ...state,
      };
      break;

    case 'saveDateTime':
      updatedState = {
        ...state,
        data: {
          ...state.data,
          [action.id]: action.value,
        },
      };
      break;
    default:
      updatedState = state;
      break;
  }

  /*** Tests purpose */
  //console.log('Updated state', action.type, 'is navigation stuck ?', updatedState.navigationStuck);
  // Set current State to session storage
  window.sessionStorage.setItem('state', JSON.stringify(updatedState));
  // set state to window.state
  window.state = updatedState;
  return updatedState;
};

function saveStep(state, step = null) {
  let savedSteps = window.sessionStorage.getItem('savedSteps') || '{}';
  savedSteps = JSON.parse(savedSteps);
  let savedCurrentStep = step
    ? step
    : Steps[state.currentStep].next
    ? Steps[state.currentStep].next.step
    : state.currentStep;
  switch (savedCurrentStep) {
    case 'UPLOAD':
    case 'PERSONNAL_DATA':
    case 'LOYALTY_LEVEL':
      savedCurrentStep = 'FILES';
      break;
    default:
      break;
  }
  savedSteps = {
    ...savedSteps,
    ...gatherData(state),
    currentStep: savedCurrentStep,
  };
  window.sessionStorage.setItem('savedSteps', JSON.stringify(savedSteps));
}

/**
 * async PostFiles
 * @description Post valid files to specific backend API
 * @param {Object} state Current State
 * @return undefined
 */
async function PostFiles(state) {
  const folder = moment().format('YYYYMMDDHHmmss');
  // For each valid(which has an input[type=file] counterpart)
  Object.keys(window.Config.filesToUpload).map(async (key, index) => {
    // Gather all input[type="file"] from the current dom thanks to data-file attribute
    const files = [...document.querySelectorAll(`[data-file="${key}"]`)];
    const filesList = {};
    // Create a dictionnary made of POST files
    const requestFiles = files.map(async (item) => {
      const file = item.files[0];
      const hashedFileName = await getHash(file.name);
      const options = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${window.Config.OauthToken}`
        }),
      };
      try {
        const f = fetch(
          `${window.Config.UploadEndpoint}?key=${hashedFileName}&claimid=${state.claimId}&folder=${folder}`,
          options
        );
        if (f.status >= 400) {
          const errorContent = await f.json();
          return {
            ...errorContent,
            error: 'ok',
            statusCode: f.status,
            statusText: f.statusText,
          };
        }
        filesList[hashedFileName] = {
          file,
          index,
        };
        return f;
      } catch (error) {
        console.error(error);
      }
    });
    const results = await Promise.all(requestFiles);
    const encodedResults = [];
    for (let item of results) {
      encodedResults.push(await item.json());
    }
    const postRequests = encodedResults.map(async (item) => {
      const hashedFileName = await getHash(item.key);
      const file = filesList[hashedFileName].file;
      const formData = new FormData();
      formData.append('data', file);
      const headers = new Headers();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
      const options = {
        method: 'PUT',
        body: formData,
        headers,
      };
      try {
        const f = await fetch(item.url, options);
        if (f.status >= 400) {
          const errorContent = await f.json();
          return {
            ...errorContent,
            error: 'ok',
            statusCode: f.status,
            statusText: f.statusText,
          };
        }
        return f;
      } catch (error) {
        console.error(error);
      }
    });
    const postRequestsResult = await Promise.all(postRequests);
    const fullResults = [];
    for (let item of postRequestsResult) {
      fullResults.push(item);
    }
  });
}

async function getHash(str, algo = 'SHA-256') {
  let strBuf = new TextEncoder('utf-8').encode(str);
  const crypto = window.crypto || window.msCrypto;
  return crypto.subtle.digest(algo, strBuf).then((hash) => {
    window.hash = hash;
    // here hash is an arrayBuffer,
    // so we'll connvert it to its hex version
    let result = '';
    const view = new DataView(hash);
    for (let i = 0; i < hash.byteLength; i += 4) {
      result += ('00000000' + view.getUint32(i).toString(16)).slice(-8);
    }
    return result;
  });
}
export default Form;

import moment from 'moment-timezone';
import Steps from '../Components/Steps/index';
import Cookie from './Cookie.js';

moment.tz.setDefault(moment.tz.guess());

const Form = {
  steps: Steps,
  isAccident: false,
  previousStep: 'PORTAL',
  currentStep: 'PORTAL',
  data: {},
  loadFile: false,
  navigationStuck: false,
  goBackStuck: false,
  dataSent: false,
  errors: {},
  draging: false,
  positionLocalised: false,
  zoom: 5.3,
  markerPosition: {
    lat: 51.50210894125497,
    lng: -0.1261891287419985,
  },
  center: {
    lat: 51.50210894125497,
    lng: -0.1261891287419985,
  },
  bounds: undefined,
  autocomplete: null,
  fingerDown: 0,
  fingerUp: 0,
  visibleDates: [],
  datesDescriptors: {
    vehicleUnavailabilityStartDate: {
      type: 'date',
      visible: false,
    },
    accidentDate: {
      type: 'date',
      visible: false,
    },
    repairStartDate: {
      type: 'date',
      visible: false,
    },
    returnDate: {
      type: 'date',
      visible: false,
    },
    repairEndDate: {
      type: 'date',
      visible: false,
    },
    newCarRegistrationDate: {
      type: 'date',
      visible: false,
    },
  },
  // Files
  visibleFiles: [],
  filesDescriptors: {
    damagedVehiclePictures: {
      type: 'file',
      visible: false,
      required: () => true,
      mandatory: true,
    },
    incidentScenePictures: {
      type: 'file',
      visible: false,
      required: () => false,
      mandatory: false,
    },
    vehicleRepairProof: {
      type: 'file',
      visible: false,
      mandatory: true,
      required: (data) => {
        return typeof data.driverToRepairIndicator !== 'undefined' && data.driverToRepairIndicator === true;
      },
    },
    vehicleReplacementProof: {
      type: 'file',
      visible: false,
      mandatory: true,
      required: (data) => {
        return typeof data.driverToRepairIndicator !== 'undefined' && data.driverToRepairIndicator === false;
      },
    },
    totalLossProof: {
      type: 'file',
      visible: false,
      mandatory: true,
      required: (data) => {
        return data.totalLossIndicator === 'true';
      },
    },
  },
  datesFields: [
    {
      name: 'vehicleUnavailabilityStartDate',
    },
  ],
  whatFields: [
    {
      name: 'incidentType',
      type: 'radio',
      choices: 'incidentTypeChoices',
      onChange: ['validateField', 'handleChangeIncidentType'],
      onBlur: ['validateField'],
      listClassName: 'Form-IncidentType',
      required: true,
    },
    {
      name: 'incidentSubType',
      type: 'single',
      choices: 'isAccidentChoices',
      visible: (data) => {
        if (data.incidentSpecification === 'accident') return true;
        return false;
      },
      className: 'Button-circle',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
    },
    {
      name: 'injuryIndicator',
      type: 'radio',
      choices: 'injuryIndicatorChoices',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
    },
    {
      name: 'incidentDescription',
      type: 'textarea',
      cols: 54,
      rows: 5,
      required: true,
      size: () => window.Config.descriptionSizeLimit,
      onBlur: ['validateField', 'limitTo500Chars'],
      onChange: ['validateField', 'limitTo500Chars'],
    },
    {
      name: 'driverVehiclePlateNumber',
      type: 'text',
      onBlur: ['validateField', 'formatDriverLicencePlate'],
      onChange: ['validateField', 'formatDriverLicencePlate'],
      required: true,
      size: () => window.Config.inlineTextSizeLimit,
    },
  ],
  whenFields: [
    {
      name: 'dateOfTheIncident-date',
      required: true,
      type: 'date',
      currentDate: (data) => {
        if (data['dateOfTheIncident-date']) {
          return data['dateOfTheIncident-date'];
        }
        return moment().format('YYYY-MM-DD');
      },
      min: moment()
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
      max: moment().format('YYYY-MM-DD'),
      onBlur: ['validateDate'],
    },
    {
      name: 'dateOfTheIncident-time',
      type: 'time',
      currentTime: (data) => {
        if (data['dateOfTheIncident-time']) {
          return data['dateOfTheIncident-time'];
        }
        return moment().format('HH:mm');
      },
      required: true,
      onBlur: ['validateDate'],
    },
    {
      name: 'incidentPartnerStatus',
      type: 'select',
      required: true,
      onChange: ['validateField'],
    },
  ],
  detailsFields: [
    {
      name: 'totalLossIndicator',
      type: 'radio',
      required: true,
      onChange: ['validateField', 'displayDriverToRepairIndicator'],
    },
    {
      name: 'driverToRepairIndicator',
      type: 'radio',
      required: true,
      onChange: ['validateField', 'displayIncidentDates'],
      visible: (data) => data.totalLossIndicator === false,
    },
    {
      name: 'vehicleUnavailabilityStartDate',
      type: 'date',
      required: true,
      visible: (data) => {
        return data.driverToRepairIndicator === false && data.totalLossIndicator === false;
      },
      min: moment()
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
      currentDate: (data) =>
        data.vehicleUnavailabilityStartDate ? data.vehicleUnavailabilityStartDate : moment().format('YYYY-MM-DD'),
      onBlur: ['validateReplacementDate'],
    },
    {
      name: 'vehicleReturnToPlatformDate',
      type: 'date',
      required: true,
      visible: (data) => data.driverToRepairIndicator === false && data.totalLossIndicator === false,
      currentDate: (data) =>
        data.vehicleReturnToPlatformDate
          ? data.vehicleReturnToPlatformDate
          : moment()
              .add(1, 'days')
              .format('YYYY-MM-DD'),
      onBlur: ['validateReplacementDate'],
    },
    {
      name: 'startedRepairIndicator',
      type: 'radio',
      required: true,
      warningMessage: 'repairMustHaveStarted',
      warningMessageCondition: (data) => data.startedRepairIndicator === false,
      visible: (data) => data.driverToRepairIndicator === true && data.totalLossIndicator === false,
      onChange: ['validateField', 'displayIncidentDates'],
    },
    {
      name: 'repairStartDate',
      type: 'date',
      required: true,
      visible: (data) =>
        data.startedRepairIndicator === true &&
        data.driverToRepairIndicator === true &&
        data.totalLossIndicator === false,
      min: moment()
        .subtract(1, 'year')
        .format('YYYY-MM-DD'),
      currentDate: (data) => (data.repairStartDate ? data.repairStartDate : moment().format('YYYY-MM-DD')),
      onBlur: ['validateRepairDate'],
    },
    {
      name: 'repairEndDate',
      type: 'date',
      required: true,
      visible: (data) =>
        data.startedRepairIndicator === true &&
        data.driverToRepairIndicator === true &&
        data.totalLossIndicator === false,
      currentDate: (data) =>
        data.repairEndDate
          ? data.repairEndDate
          : moment()
              .add(1, 'days')
              .format('YYYY-MM-DD'),
      onBlur: ['validateRepairDate'],
    },
  ],
  thirdPartyFields: [
    {
      name: 'thirdPartyVehiclePlateNumber',
      type: 'text',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
    },
    {
      name: 'thirdPartyDriverFirstName',
      type: 'text',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
    },
    {
      name: 'thirdPartyDriverLastName',
      type: 'text',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
    },
    {
      name: 'thirdPartyDriverPhoneNumber',
      type: 'text',
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
    },
  ],
  loyaltyField: [
    {
      name: 'driverDeclaredLoyaltyLevel',
      type: 'select',
      onChange: ['validateField'],
      onBlur: ['validateField'],
      required: true,
    },
  ],

  personnalInformations: [
    {
      name: 'recipientFirstName',
      type: 'text',
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder) {
          return claim.policyholder.firstName;
        }
        return '';
      },
      required: true,
      size: () => window.Config.inlineTextSizeLimit,
    },
    {
      name: 'recipientLastName',
      type: 'text',
      size: () => window.Config.inlineTextSizeLimit,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder) {
          return claim.policyholder.lastName;
        }
        return '';
      },
      required: true,
    },
    {
      name: 'recipientBirthDate',
      type: 'date',
      size: () => window.Config.inlineTextSizeLimit,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder) {
          return claim.policyholder.birthDate;
        }
        return '';
      },
      required: true,
      min: moment()
        .subtract(100, 'years')
        .format('YYYY-MM-DD'),
      current: moment()
        .subtract(18, 'years')
        .format('YYYY-MM-DD'),
      max: moment()
        .subtract(18, 'years')
        .format('YYYY-MM-DD'),
    },
    {
      name: 'recipientPhoneNumber',
      type: 'tel',
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
      patter: '[0-9]+',
      value: (claim) => {
        if (claim && claim.policyholder) {
          return claim.policyholder.phoneNumber;
        }
        return '';
      },
      required: true,
    },
    {
      name: 'recipientEmail',
      type: 'email',
      onChange: ['validateField'],
      onBlur: ['validateField'],
      size: () => window.Config.inlineTextSizeLimit,
      pattern: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
      value: (claim) => {
        if (claim && claim.policyholder) {
          return claim.policyholder.email;
        }
        return '';
      },
      required: true,
    },
    {
      name: 'recipientStreetAddress',
      type: 'text',
      size: () => window.Config.inlineTextSizeLimit,
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder && claim.policyholder.postalAddress && claim.policyholder.postalAddress.route) {
          const street_number = claim.policyholder.postalAddress.street_number || '';
          const route = claim.policyholder.postalAddress.route;
          return `${street_number} ${route}`;
        }
        return '';
      },
    },
    {
      name: 'recipientMunicipality',
      type: 'text',
      size: () => window.Config.inlineTextSizeLimit,
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder && claim.policyholder.postalAddress) {
          const town =
            claim.policyholder.postalAddress.locality ||
            claim.policyholder.postalAddress.municipality ||
            claim.policyholder.postalAddress.postal_town;
          return town;
        }
        return '';
      },
    },
    {
      name: 'recipientPostCode',
      type: 'text',
      size: () => window.Config.inlineTextSizeLimit,
      required: true,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      value: (claim) => {
        if (claim && claim.policyholder && claim.policyholder.postalAddress) {
          return claim.policyholder.postalAddress.postal_code;
        }
        return '';
      },
    },
    {
      name: 'recipientCountry',
      type: 'select',
      size: () => window.Config.inlineTextSizeLimit,
      onChange: ['validateField'],
      onBlur: ['validateField'],
      required: true,
      selectedIndex: () => 'GB',
      value: () => '',
      disabled: 'disabled',
    },
    {
      name: 'recipientAccountNumber',
      type: 'text',
      rel: 'modulus',
      size: () => window.Config.inlineTextSizeLimit,
      onChange: ['validateField', 'validateAccountNumber'],
      onBlur: ['validateField', 'validateModulusChecking'],
      required: true,
      value: (claim) => {
        if (claim) {
          return claim.accountNumber;
        }
        return '';
      },
    },
    {
      name: 'recipientSortCode',
      type: 'text',
      rel: 'modulus',
      size: () => window.Config.inlineTextSizeLimit,
      onChange: ['validateField', 'validateSortCode'],
      onBlur: ['validateField', 'validateModulusChecking'],
      required: true,
      value: (claim) => {
        if (claim) {
          return claim.sortCode;
        }
        return '';
      },
    },
  ],
  continueField: [
    {
      name: 'claim-id-to-resume',
      type: 'text',
      required: true,
      pattern: '^[0-9]{8,10}$',
      onBlur: ['validateClaimStep1Id'],
      onChange: ['validateClaimStep1Id'],
      defaultValue: (props) => {
        if (Cookie.read('pendingClaim')) {
          return Cookie.read('pendingClaim');
        }
        return null;
      },
    },
  ],
};

export default Form;

const thirdPartyFields = Form.thirdPartyFields;
const personnalInformations = Form.personnalInformations;
const datesFields = Form.datesFields;
const whatFields = Form.whatFields;
const whenFields = Form.whenFields;
const detailsFields = Form.detailsFields;
const loyaltyField = Form.loyaltyField;
const continueField = Form.continueField;
export {
  thirdPartyFields,
  personnalInformations,
  datesFields,
  whatFields,
  whenFields,
  detailsFields,
  loyaltyField,
  continueField,
};

const en = {
  IENotSupported: 'Sorry, but your browser is not supported. Please use a more recent version.',
  portal: {
    intro: 'Welcome to AXA XL Vehicle Interruption Cover Application.',
  },
  disclaimer: {
    explanations: [
      'Welcome to the AXA XL Claims Declaration Portal',
      'There are 2 steps to this process:',
      '1) Report your incident. Here you will be asked to provide information regarding the incident, including what happened, when it happened and where.',
      "2) Upload your supporting evidence. Here you will need to upload evidence to prove your claim and give us more detail about the incident. Don't worry if you don't have the information now, you can come back later.",
    ],
  },
  validation: {
    explanations: [
      'You have successfully passed the first step of your claim declaration!',
      'Before going any further with your claim declaration, check if you have the following documents / pictures:',
      '1) At least one picture of the damaged vehicle with its plate number visible',
      '2) A picture of the incident scene',
      '3) At least one repair (quotation or invoice) or replacement document indicating the completion date',
      'If you want to complete this last step later, you can use the resume link inside your notification of loss email.',
    ],
  },
  conclusion: {
    explanations: [
      'We will process your claim submission or contact you for more information shortly.',
      'Please quote your claim number %claimid% if you contact us.',
    ],
  },
  lang: {
    en: 'English / en',
    fr: 'French / fr',
  },
  errors: {
    label: `We have received your notification of loss. <br />
    <br />
      After reviewing your submission, unfortunately our record shows that your claim is not covered because `,
    otherException:
      'Sorry, this product is intended to only cover accident-related vehicle interruption, you do not appear to be eligible to claim on this occasion',
    format: 'Format is not valid.',
    mandatory: 'Field is mandatory.',
    dateIsNotValid: 'Date is not valid.',
    dateIsInTheFuture: 'You cannot set a date in the future.',
    dateMustHaveOneDayOfDifference: 'Start date and end date must be at least 24 hours apart.',
    noIncidentLocation: 'No incident location defined.',
    size: `There are too many characters (maximum %size%)`,
    size500: 'There are too many characters (maximum 500 characters)',
    geolocationFromNavigatorIsNotAvailable:
      'Your browser is not allowed to access your position. Please use the text field above.',
    incidentPartnerStatus: 'Please select the status of your app when the incident happened',
    InvalidIncidentLocation: 'You have declared a claim for an incident that did not happen in the United Kingdom',
    resource_not_found_title: 'Uber account without policy',
    resource_not_found: 'This Uber account does not have any Vehicle Interruption Cover policy.',
    re_authentication_process:
      'You may need to authenticate with the correct Uber account through the following steps:',
    re_authentication_process_li_1: 'Sign out from this website using the top right menu,',
    re_authentication_process_li_2: 'Sign out from your current Uber account: https://drivers.uber.com,',
    re_authentication_process_li_3: 'Sign in again on this website using the correct Uber credentials,',
    re_authentication_process_resource_not_found: 'Finally retry to declare your claim.',
    claim_not_found_title: 'The claim you asked to complete could not be found.',
    claim_not_found: 'This claim reference is either incorrect or not associated to this Uber account.',
    re_authentication_process_claim_not_found_error_404: 'Finally retry to load your claim.',
    invalid_credentials_title: 'Session terminated',
    Unauthorized_title: 'Session terminated',
    Unauthorized:
      'Your session has expired. We will re-authenticate you with your Uber credentials so you can continue your claim declaration.',
    invalid_credentials:
      'Your session has expired. We will re-authenticate you with your Uber credentials so you can continue your claim declaration.',
    schema_validation_failed_title: 'Technical error',
    schema_validation_failed: 'The request does not have required parameters or fails the validation',
    claim_complete_title: 'Claim already completed',
    claim_complete: 'You have already completed this claim.',
    internal_server_error_title: 'Technical error',
    internal_server_error:
      'The server encountered an unexpected condition which prevented it from fulfilling the request',
    repairMustHaveStarted: 'The repair must have begun before the claim declaration can be completed.',
    login_again: 'We are going to try to log you to Uber',
  },
  selectDefaultOption: {
    label: 'Please choose an option',
    yes: 'Yes',
    no: 'No',
  },
  defaultValue: {
    licencePlate: 'AB12CDE',
  },
  formSteps: {
    PORTAL: 'Vehicle Interruption insurance cover with AXA XL in the UK',
    DISCLAIMER: 'Claim declaration',
    WHAT: 'What happened?',
    WHEN: 'When did it happen?',
    WHERE: 'Where did it happen?',
    RESULTS: 'Recap...',
    VALIDATION: 'Claim declaration... almost complete!',
    UPLOAD: 'Upload files',
    PERSONNAL_DATA: 'Payment information',
    CONCLUSION: 'Thank you for completing your declaration',
    NOT_COVERED: 'Claim acknowledgement with no coverage',
    DETAILS: 'Complete your claim',
    THIRD_PARTY_INFORMATION: 'Third-party information',
    LOYALTY_LEVEL: 'Loyalty level',
    FILES: 'Evidence',
    ERROR: '%error_code%',
    FAQ: 'FAQ',
    PRIVACY_POLICY: 'Privacy Policy',
    CONTINUE_CLAIM: 'Complete a claim',
  },
  NotCovered: {
    InvalidIncidentLocation: {
      text: 'you have declared a claim for an incident that did not happen in the United Kingdom',
      title: 'Claim acknowledgement with no coverage',
    },
    NoActivePolicyAtIncidentTime: {
      text:
        'you did not have an active insurance policy with us at the time of the incident that resulted in your claim',
      title: 'Claim acknowledgement with no coverage',
    },
    InvalidExposure: {
      text:
        'the reason you were unable to drive your vehicle for a period of 24 hours or longer was not related to an accident (as defined in your policy)',
      title: 'Claim acknowledgement with no coverage',
    },
    ClaimLimitReached: {
      text: 'you have reached the maximum entitlement of two (2) claims for benefit payments under this policy',
      title: 'Claim acknowledgement with no coverage',
    },
    DuplicatedClaim: {
      text: 'you already have declared a claim in the same incident period as the one you are submitting',
      title: 'Claim acknowledgement with no coverage',
    },
  },
  signedOut: {
    title: 'Sign out',
    text: 'To avoid being automatically re-authenticated, you need to sign out from your Uber account as well.',
  },
  navigation: {
    start_claim: 'File a new claim',
    continue_claim: 'Complete a claim',
    start_back: 'Start again',
    start_details: 'Continue my claim',
    keep_for_later: 'Postpone my claim',
    nextStep: 'Next Step',
    previousStep: 'Previous Step',
    submit: 'Validate the claim',
    validateFirstStep: 'Validate the claim',
    submitForm: 'Validate the claim',
    back: 'Previous Step',
    back_to_portal: 'Home',
    please_try_again: 'Previous step',
    faq: 'faq',
    privacy_policy: 'Privacy Policy',
    tnc: 'T & C',
    'continue-claim': 'Complete my Claim',
    cancel: 'Cancel',
    saveFiles: 'Save file(s)',
    confirm: 'Confirm',
    ok: 'OK',
    login_again: 'Login through Uber website',
    logout: 'Sign out',
    back_to_uber: 'Sign out',
  },

  map: {
    label: 'Incident location',
    NoLocationFound: 'No Address found.',
    cantUpdatePlaceFromNavigator: 'Your browser is blocking access to your location. ',
    'User denied Geolocation': 'Your browser is blocking access to your location. ',
    search: 'Search Places ...',
    loading: 'Loading location data...',
    explanations: 'You can long-click on the map to add a marker or type an address on the text field.',
  },
  result: {
    loading: 'Loading, please wait.',
    modifyThisStep: 'Correct the above information',
    explanations: 'Please check the data you have entered. If required, please correct it in the corresponding step.',
  },
  preselects: {
    label: 'Repair details',
  },
  dates: {},
  files: {
    placeholder: 'Click to upload file',
    loading: 'Document loading to browser, please wait.',
    tooBig: 'Document is larger than 5MB. It may take a while to upload.',
    forbidden: 'This file cannot be uploaded as it exceeds the maximum size limit of 25 MB.',
    hint: {
      label: 'Please choose at least one picture of the',
      sizeLimit: 'We recommend to upload files lower than 5MB',
      incidentScenePictures: 'Please upload photos regarding the incident scene. (png, jpeg, jpg...)',
      damagedVehiclePictures:
        'Your damaged vehicle pictures. Please make sure your licence plate is visible on at least one picture. (png, jpeg, jpg...)',
      vehicleRepairProof: 'Please upload your vehicle repair proof documents (PDF, jpeg, png...)',
      vehicleReplacementProof:
        'For a leased vehicle, an invoice or fee from the leasing company proving that the vehicle had to be repaired or replaced. (pdf, jpeg, png...)',
      totalLossProof:
        'Please upload a document from your insurer proving that the vehicle is a total loss, or a picture of the vehicle proving the total loss.',
    },
    descriptors: {
      thumbnail: 'Thumbnail',
      name: 'Name',
      size: 'Size',
      extention: 'Ext.',
      remove: 'Del.',
    },
    noFile: 'Please select a file.',
    add: 'Add files',
    update: 'Update files',
    delete: 'Delete',
    selectAFile: 'Select a file',
    description: 'Add description details if needed...',
    totalLossProof: 'Proof of the total loss of the vehicle',
    incidentScenePictures: 'Pictures of the incident scene',
    damagedVehiclePictures: 'Pictures of your damaged vehicle',
    vehicleRepairProof: 'Garage repair estimate or invoice',
    vehicleReplacementProof: 'Invoice or fee from the leasing company',
  },
  IncidentWThirdParty: 'If the incident involved another party please provide the following information:',
  text: {
    claimNotCompleted: {
      label: 'You have a non completed claim, ID: ',
      continue: 'Complete my claim now',
    },
    'claim-id-to-resume': {
      label: 'Claim reference',
      placeholder: '1000123456',
      hint: 'You will find your claim reference in the email you received after your notification of loss.',
      continue: 'You have a claim saved in your browser with the number:',
    },
    userAcceptance: {
      label:
        'By checking this box, I confirm that I authorise AXA and Uber to collect my information for the purposes of application usability.',
    },
    totalLossIndicator: {
      label: 'Is your vehicle a total loss?',
      true: 'Yes',
      false: 'No',
    },
    driverToRepairIndicator: {
      label: 'Are you or your insurers in charge of the repairs?',
      true: 'Yes',
      false: 'No',
    },
    carImmobilizedDueToAccident: {
      label: 'Is the vehicle immobilised due to the incident?',
      true: 'Yes',
      false: 'No',
    },
    startedRepairIndicator: {
      label: 'Has the repair started?',
      true: 'Yes',
      false: 'No',
    },
    incidentType: {
      label: 'Nature of the incident',
      theft: 'Theft',
      accident: 'Accident',
      fire: 'Fire',
      attemptedTheft: 'Attempted or recovered theft',
      maliciousDamage: 'Malicious Damage',
      windscreenDamage: 'Windscreen Damage',
      other: 'Other',
      hint: 'Indicate what kind of incident occurred.',
      covered: '(Is insured)',
      notCovered: '(Is not insured)'
    },
    incidentDescription: {
      label: 'Describe what happened',
      placeholder: 'Please provide as much detail as possible...',
      hint: 'Please describe in detail what happened (%size% characters maximum).',
    },
    injuryIndicator: {
      label: 'Has anyone been injured in the incident?',
      false: 'No',
      true: 'Yes',
      hint: '',
    },
    incidentSubType: {
      label: 'Did the incident involve a third-party vehicle?',
      withoutThirdParty: 'No',
      withThirdParty: 'Yes, and I know the vehicle',
      withUnknownThirdParty: 'Yes, but I do not know the vehicle',
    },
    driverVehiclePlateNumber: {
      label: 'Plate number',
      placeholder: 'AB12CDE',
      hint: 'Please enter the plate number of your damaged vehicle.',
    },
    dateOfTheIncident: {
      date: 'Date of incident',
      dateExample: 'Example: dd/mm/yyyy',
      time: 'Time of incident',
      timeExample: 'Example: 09h41',
      at: 'at',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    incidentPartnerStatus: {
      label: 'Uber trip status',
      hint: 'Your Uber trip status when the incident occurred',
      P0: 'Uber application off',
      P1: 'Uber application on',
      P2: 'En-route to pick-up a customer',
      P3: 'On-trip with a customer',
    },
    vehicleUnavailabilityStartDate: {
      label: 'Date from which you have been unable to use your vehicle',
      hint: 'Date format YYYY-MM-DD — e.g. 1981-12-31',
    },
    vehicleReturnToPlatformDate: {
      label: 'Date of return to normal activities with Uber (estimated or actual)',
      hint: 'Date format YYYY-MM-DD — e.g. 1981-12-31',
    },
    'dateOfTheIncident-date': {
      label: 'Date of incident (or its discovery)',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    'dateOfTheIncident-time': {
      label: 'Time of incident',
      hint:
        'Time format HH:mm - e.g 21:46',
    },
    accidentDate: {
      label: 'Incident Date',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    repairStartDate: {
      label: 'Please specify repair start date',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    repairEndDate: {
      label: 'Date of repair completion (estimated or actual)',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    newCarRegistrationDate: {
      label: 'New car registration date',
    },
    driverDeclaredLoyaltyLevel: {
      label: 'Your loyalty level at the time of incident',
      hint: 'All information will be verified with Uber.',
      partner: 'Partner',
      gold: 'Gold',
      platinum: 'Platinum',
      diamond: 'Diamond',
    },
    recipientFirstName: {
      label: 'Your first name',
      placeholder: 'John',
    },
    recipientLastName: {
      label: 'Your last name',
      placeholder: 'Smith',
    },
    recipientBirthDate: {
      label: 'Your birthdate',
      placeholder: '01/01/1981',
      hint: 'Date format YYYY-MM-DD – e.g. 1981-12-31',
    },
    recipientEmail: {
      label: 'Your email',
      placeholder: 'john.smith@uber.com',
    },
    recipientPhoneNumber: {
      label: 'Your phone Number',
      placeholder: '+44123456789',
      hint: 'If your phone number is not a UK one, add the international code +XX',
    },
    recipientStreetAddress: {
      label: 'Your street address',
      placeholder: '22 High Street',
    },
    recipientPostCode: {
      label: 'Your postal code',
      placeholder: 'NW1 1AA',
    },
    recipientMunicipality: {
      label: 'Your municipality',
      placeholder: 'LONDON',
    },
    recipientCountry: {
      label: 'Your country',
      placeholder: 'United Kingdom',
      hint: 'Only addresses within the United Kingdom are allowed',
      warning: 'Please check your address carefully',
    },
    recipientAccountNumber: {
      label: 'Account Number',
      placeholder: '...',
      hint: 'Provide a correct account number valid with Bacs Direct Credit of Direct Debit e.g. 01234567',
    },
    recipientSortCode: {
      label: 'Sort Code',
      placeholder: '...',
      hint: 'Provide a correct Sort Code valid with Bacs Direct Credit of Direct Debit e.g. 01-2345 or 01-23-45',
    },
    thirdPartyVehiclePlateNumber: {
      label: 'Third-party vehicle plate number',
      placeholder: 'AB12CDE',
      hint: 'Provide the third-party vehicle plate number',
    },
    thirdPartyDriverFirstName: {
      label: 'Third-party vehicle driver first name',
      placeholder: 'John',
      hint: 'Provide the third-party vehicle driver first name',
    },
    thirdPartyDriverLastName: {
      label: 'Third-party vehicle driver last name',
      placeholder: 'Doe',
      hint: 'Provide the third-party vehicle driver last name',
    },
    thirdPartyDriverPhoneNumber: {
      label: 'Third-party vehicle driver phone number',
      placeholder: '+44987654321',
      hint: 'Provide the third-party vehicle driver phone number e.g. +44987654321',
    },
  },
};

export default en;

import APIError from './Errors';
import Form from './Form';

const Where = {
  savePlaces: ({ state, results, lat, lng }) => {
    let place = results[0];
    saveAddress(
      {
        data: {
          place
        }
      },
      state,
      lat,
      lng
    );
  },
  updatePlaces: ({ state, action }) => {
    const place = action.results[0];
    const updatedState = {
      ...state,
      navigationStuck: false,
      loadingPlace: false,
      markerPosition: {
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng
      },
      center: {
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng
      }
    };
    //result
    saveAddress(
      {
        data: {
          place
        }
      },
      state,
      place.geometry.location.lat,
      place.geometry.location.lng
    );
    return updatedState;
  },
  erasePlace: ({ state, action }) => {
    const updatedState = {
      ...state,
      loadingPlace: false,
      navigationStuck: true,
      markerPosition: Form.markerPosition,
      center: Form.center,
      data: {
        ...state.data,
        incidentLocation: state.data.incidentLocation
      }
    };
    document.querySelector('#gh-Form-Where-autocomplete-input').value = '';
    return updatedState;
  },
  cantUpdatePlacesFromNavigator: ({ state, action }) => {
    const updatedState = {
      ...state,
      loadingPlace: false
    };
    return updatedState;
  },
  centerOnMap: ({ state, action }) => {
    const updatedState = {
      ...state,
      navigationStuck: false,
      markerPosition: action.center
    };
    return updatedState;
  },
  handleLongClick: (status, { state, action }) => {
    const lat = action.event.latLng.lat();
    const lng = action.event.latLng.lng();
    if (state.draging) {
      return null;
    }
    if (status === 'Up') {
      // Avoid entering inside the object if the user is draging
      state.fingerUp = new Date().getTime();
      if (
        state.fingerUp - state.fingerDown > 500 &&
        typeof action.event !== 'undefined'
      ) {
        const updatedState = {
          ...state,
          navigationStuck: false,
          markerPosition: {
            lat,
            lng
          }
        };
        Where.getDataFromGMaps(lat, lng, res => {
          if (res.error) {
            state.networkError(res.error);
            return false;
          }
          if (res.status === 'ZERO_RESULTS') {
            return updatedState;
          }
          console.log(res.results);
          Where.savePlaces({
            state,
            results: res.results,
            lat,
            lng
          });
        });
        return updatedState;
      } else {
        console.log('Short click');
      }
    }

    if (status === 'Down') {
      console.log('Status down', state);
      const updatedState = {
        ...state,
        fingerDown: new Date().getTime()
      };
      return updatedState;
    }

    return state;
  },
  handleZoomChanged: ({ state, action }) => {
    const updatedState = {
      ...state
    };
    if (window.gmap) {
      updatedState.center = {
        lat: window.gmap.center.lat(),
        lng: window.gmap.center.lng()
      };
    }
    return updatedState;
  },
  handleDraging: (status, { state, action }) => {
    return {
      ...state,
      center: action.center,
      draging: status === 'start'
    };
  },
  handleIdle: ({ state }) => {
    return {
      ...state
    };
  },
  handleUnloadMap: ({ state, action }) => {
    window.gmap = undefined;
    console.log('gmap is killed');
    return { ...state };
  },
  getDataFromGMaps: async (lat, lng, then) => {
    if (!lat || !lng) {
      throw new Error('No Lat or Lng initialized');
    }
    try {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
      headers.append('Content-Type', 'text/plain');
      const f = await fetch(window.Config.GoogleMapAPIEndpoint, {
        method: 'POST',
        body: JSON.stringify({
          lat,
          lng
        }),
        headers
      });
      if (f.status >= 400) {
        const errorContent = await f.json();
        const error = {
          error: 'ok',
          statusCode: f.status,
          statusText: f.statusText,
          ...errorContent
        };
        then(error);
        return false;
      }
      const j = await f.json();
      then(j);
    } catch (error) {
      throw new APIError(error.code, error.message, error.stack);
    }
  }
};

export default Where;

function saveAddress(res, state, lat, lng) {
  if (!lat || !lng) {
    throw new Error('Save Address, No lat or lng !');
  }
  const { place } = res.data;
  const address = place.address_components
    ? place.address_components
    : place.formatted_address.split(', ');

  let streetAddress = '';
  if (!!place.address_components) {
    streetAddress = formatStreetAddress(place.address_components);
  } else {
    streetAddress = place.formatted_address.split(', ')[0];
  }

  let postCode = '';
  if (place.address_components) {
    postCode = place.address_components.filter(element => {
      return (
        element.types[0] === 'postal_code' ||
        element.types[0] === 'postal_code_prefix'
      );
    });
  } else {
    postCode = [{ long_name: address[1].split(' ')[0] }];
  }
  let municipality = '';
  if (place.address_components) {
    municipality = place.address_components.filter(element => {
      return (
        element.types[0] === 'postal_town' || element.types[0] === 'locality'
      );
    });
  } else {
    municipality = [{ long_name: address[1].split(' ')[1] }];
  }

  let countryName = 'undefined';
  if (place.address_components) {
    countryName = place.address_components.filter(element => {
      return element.types[0] === 'country';
    });
  } else {
    countryName = [{ long_name: address[2], short_name: 'undefined' }];
  }
  state.data.incidentLocation = {
    streetAddress,
    postCode: postCode[0] ? postCode[0].long_name : 'undefined',
    municipality: municipality[0] ? municipality[0].long_name : '',
    country: {
      name: countryName[0] ? countryName[0].long_name : '',
      code: countryName[0] ? countryName[0].short_name : ''
    },
    globalPositionAddress: {
      latitude: lat,
      longitude: lng
    }
  };
  state = {
    ...state,
    navigationStuck: false,
    center: {
      lat,
      lng
    },
    markerPosition: {
      lat,
      lng
    }
  };
  window.autocomplete.input.value = place.formatted_address;
  window.sessionStorage.setItem('state', JSON.stringify(state));
}

function formatStreetAddress(components) {
  const streetNumber = components[0] ? components[0].long_name : '';
  const streetName = components[1] ? components[1].long_name : '';
  return `${streetNumber} ${streetName}`;
}

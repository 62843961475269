import en from './en.js';
import fr from './fr.js';

const langs = {
  en,
  fr
};

export default function(lang = 'en') {
  return langs[lang];
}

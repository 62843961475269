const What = {
  handleFormErrors: (status, { state, action }) => {
    const formInputError = {};
    formInputError[action.field.name] = status;
    return {
      ...state,
      errors: formInputError
    };
  },
  isAnAccident: (element, { state }) => {
    const incidentSpecification = element.getAttribute('incidentspecification');
    if (
      incidentSpecification === 'other' &&
      typeof state.data.incidentSubType !== 'undefined'
    ) {
      delete state.data.incidentSubType;
    }
    return {
      ...state,
      data: {
        ...state.data,
        incidentType: element.value,
        incidentSpecification
      }
    };
  }
};

export default What;

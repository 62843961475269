import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadLiterals } from '../../Store/Literals';
import loadLang from '../../i18n';
import Prismic from 'prismic-javascript';

import Cookie from '../../Utils/Cookie';

import Menu from '../../Utils/Menu.js';

import './Menu.scss';

async function List() {
  const repository = Prismic.getApi(window.Config.prismicEndpoint, {
    ref: 'Master',
    accessToken: window.Config.prismicKey,
  });
  const response = await repository.then((a) => a.query(Prismic.Predicates.at('my.menu.uid', 'menu-private')));
  const document = response.results[0];
  return document.data.items;
}

function MenuItem({ props, item, toggleMenu }) {
  return (
    <li className="section">
      <button
        id={`gh-${item.code.toLowerCase()}`}
        onClick={() => {
          if (item.link && item.link.link_type === 'Web') {
            window.open(item.link.url);
          } else {
            toggleMenu();
            return props.updateCurrentStep(item.code);
          }
        }}
      >
        {item.name}
      </button>
    </li>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props, list: [], isIE: false, open: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  async componentDidMount() {
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
      this.setState({ isIE: true });
    }
    this.setState({
      list: await List(),
    });
  }

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <React.Fragment>
        <nav className={`Menu`}>
          <section className="section-col section-col-100 ">
            {this.state.isIE ? <div className="Menu-IENotSupported">{this.props.Literals.IENotSupported}</div> : ''}
            <section className="section-col section-col-90">
              <figure className="Menu-logo">
                <img src="/Images/logo.svg" alt="AXA" className="Menu-logo-image" />
                <img src="/Images/axa_identifier_xl_insurance.png" alt="AXA XL Insurance" className="Menu-logo-XL" />
              </figure>
            </section>
            <section className="section-col section-col-10">
              <button
                className="Menu-Button"
                id="Menu-Button"
                onClick={() => {
                  this.toggleMenu();
                }}
              >
                <span className="Menu-Burger" id="Menu-Burger" />
              </button>
            </section>
          </section>
          <ul className={`Menu-List ${this.state.open ? 'open visible' : ''}`} id="Menu-List">
            {this.state.list
              ? this.state.list.map((item, key) => {
                  return <MenuItem key={key} props={this.props} toggleMenu={this.toggleMenu} item={item} />;
                })
              : ''}
            {window.location.hostname === 'uber-vic.localtest.me' ? (
              <li className="section">
                <button onClick={(e) => this.props.refreshCache()}>Refresh Cache</button>
              </li>
            ) : (
              ''
            )}
          </ul>
          {this.props.Literals.formSteps[this.props.currentStep] ? (
            <h2 className="section-col section-col-100 section">
              {this.props.Literals.formSteps[this.props.currentStep]}
            </h2>
          ) : (
            ''
          )}
        </nav>
        <div
          className={`Menu-overlay ${this.state.open ? 'Menu-overlay-open' : ''}`}
          onClick={() => {
            this.toggleMenu();
          }}
        ></div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Menu,
  currentStep: typeof state.Form.currentStep !== 'undefined' ? state.Form.currentStep : 'DISCLAIMER',
  Literals: state.Literals,
});

const mapDispatchToProps = (dispatch) => ({
  refreshCache: () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    Cookie.erase('pendingClaim');
    window.location.reload();
  },
  updateCurrentStep: (currentStep) => {
    dispatch({ type: 'gotoStep', currentStep, way: 'previous' });
    Menu.close();
  },
  openMenu: (event) => {
    if (Menu.status === 'close') {
      Menu.open();
      Menu.status = 'open';
    } else {
      Menu.close();
      Menu.status = 'close';
    }
  },
  udpateLang: (event) => {
    let lang = loadLang(event.target.options[event.target.selectedIndex].value);
    lang = { ...lang, currentLang: event.target.getAttribute('lang') };
    dispatch(loadLiterals(lang));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

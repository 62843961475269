const Steps = {
  PORTAL: {
    stepIndex: 1,
    Component: 'Portal',
  },
  SIGNOUT: {
    stepIndex: 1,
    Component: 'Signout',
  },
  DISCLAIMER: {
    stepIndex: 1,
    navigationStuck: true,
    Component: 'Disclaimer',
    next: {
      step: 'WHAT',
      label: 'start_claim',
      className: 'Navigation-CenterButton Button-hollow',
      onClick: 'gotoStep',
    },
  },
  WHAT: {
    stepIndex: 1,
    Component: 'What',
    next: {
      step: 'WHEN',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
      onClick: 'nextStep',
    },
  },
  WHEN: {
    stepIndex: 1,
    Component: 'When',
    previous: {
      step: 'WHAT',
      label: 'previousStep',
      className: 'Navigation-LeftButton Button-hollow',
      onClick: 'gotoStep',
    },
    next: {
      step: 'WHERE',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
      onClick: 'nextStep',
    },
  },
  WHERE: {
    stepIndex: 1,
    Component: 'Where',
    previous: {
      step: 'WHEN',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
      label: 'previousStep',
    },
    next: {
      step: 'RESULTS',
      onClick: 'gotoStep',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  RESULTS: {
    stepIndex: 1,
    Component: 'Result',
    displayLoader: true,
    next: {
      step: 'VALIDATION',
      label: 'submitForm',
      onClick: 'validateClaimStep1',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  VALIDATION: {
    stepIndex: 2,
    Component: 'Validation',
    displayLoader: true,
    next: {
      step: 'DETAILS',
      label: 'start_details',
      onClick: 'gotoStep',
      className: 'Navigation-RightButton Button-orange',
    },
    previous: {
      step: 'PORTAL',
      label: 'keep_for_later',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
    },
  },
  DETAILS: {
    stepIndex: 2,
    Component: 'Details',
    next: {
      step: conditionDetails,
      label: 'nextStep',
      onClick: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  THIRD_PARTY_INFORMATION: {
    Component: 'ThirdPartyInformation',
    stepIndex: 2,
    previous: {
      step: 'DETAILS',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
      label: 'previousStep',
    },
    next: {
      step: 'FILES',
      onClick: 'nextStep',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  FILES: {
    stepIndex: 2,
    Component: 'Files',
    displayLoader: true,
    previous: {
      step: conditionFILES,
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
      label: 'previousStep',
    },
    next: {
      step: 'LOYALTY_LEVEL',
      onClick: 'nextStep',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  LOYALTY_LEVEL: {
    Component: 'Loyalty',
    stepIndex: 2,
    previous: {
      step: 'FILES',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
      label: 'previousStep',
    },
    next: {
      step: 'PERSONNAL_DATA',
      onClick: 'nextStep',
      label: 'nextStep',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  PERSONNAL_DATA: {
    Component: 'PersonnalData',
    stepIndex: 2,
    displayLoader: true,
    next: {
      step: 'CONCLUSION',
      label: 'submitForm',
      onClick: 'validateClaimStep2',
      className: 'Navigation-RightButton Button-orange',
    },
    previous: {
      step: 'LOYALTY_LEVEL',
      label: 'back',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
    },
  },
  CONCLUSION: {
    Component: 'Conclusion',
    stepIndex: 2,
  },
  LOAD_CLAIM: {
    Component: 'LoadClaim',
    stepIndex: 2,
  },
  CONTINUE_CLAIM: {
    Component: 'ContinueClaim',
    stepIndex: 2,
    displayLoader: true,
    previous: {
      step: 'PORTAL',
      label: 'cancel',
      onClick: 'gotoStep',
      className: 'Navigation-LeftButton Button-hollow',
    },
    next: {
      step: 'LOAD_CLAIM',
      label: 'continue-claim',
      onClick: 'validateClaimStep1Id',
      className: 'Navigation-RightButton Button-orange',
    },
  },
  NOT_COVERED: {
    Component: 'NotCovered',
    stepIndex: 2,
  },
  UPLOAD: {
    Component: 'FileUploader',
    stepIndex: 2,
    displayLoader: true,
    next: {
      step: 'FILES',
      label: 'confirm',
      onClick: 'saveFilesAndGotoStep',
      className: 'Navigation-RightButton Button-orange',
    },
    previous: {
      step: 'FILES',
      label: 'cancel',
      onClick: 'emptyCurrentFilesAndGotoStep',
      className: 'Navigation-LeftButton Button-hollow',
    },
  },
  FAQ: {
    noNavigation: true,
    Component: 'faq',
    stepIndex: 2,
    previous: {
      step: 'PORTAL',
    },
  },
  PRIVACY_POLICY: {
    noNavigation: true,
    Component: 'privacyPolicy',
    stepIndex: 2,
    previous: {
      step: 'PORTAL',
    },
  },
  ERROR404: {
    noNavigation: true,
    Component: 'Error404Component',
    stepIndex: 2,
  },
  ERROR: {
    noNavigation: true,
    Component: 'ErrorComponent',
    stepIndex: 2,
  },
};

function conditionDetails(props) {
  return props.claim.incidentSubType && props.claim.incidentSubType === 'withThirdParty'
    ? 'THIRD_PARTY_INFORMATION'
    : 'FILES';
}

function conditionFILES(props) {
  return props.claim.incidentSubType && props.claim.incidentSubType === 'withThirdParty'
    ? 'THIRD_PARTY_INFORMATION'
    : 'DETAILS';
}

export default Steps;

const Menu = {
  status: 'close',
  open() {
    const Menu = document.getElementById('Menu-List');
    Menu.classList.add('visible');
    const button = document.getElementById('Menu-Burger');
    button.classList.add('open');
    const body = document.querySelector('.Form-Section');
    body.classList.add('blurry');
  },
  close() {
    const Menu = document.getElementById('Menu-List');
    Menu.classList.remove('visible');
    const button = document.getElementById('Menu-Burger');
    button.classList.remove('open');
    const body = document.querySelector('.Form-Section');
    body.classList.remove('blurry');
  }
};

export default Menu;

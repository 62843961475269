import sendErrorToBackEnd from './logErrors'

class APIError extends Error {
  constructor(
    code = 500,
    message = 'internal_server_error',
    trace = undefined
  ) {
    super();
    this.name = 'APIError';
    this.statusCode = code;
    this.message = message;
    this.trace = trace;
    this.date = new Date();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
    const content = `
<html>

<head>
  <title>AXA XL Insurance Company UK Limited - Vehicle Interruption Cover</title>
  <style>
    body {
      font-family: arial, sans-serif;
      padding: 0;
      margin: 0;
    }

    a.button {
      display: block;
      border: 1px solid black;
      border-radius: 3px;
      background-color: rgb(230, 230, 230);
      text-shadow: 1px 1px white;
      color: black;
      padding: 5px;
      text-decoration: none;
      max-width: 50vw;
      margin: 10px auto;
      text-align: center;
    }

    .main {
      padding: 20px;
    }

    blockquote {
      font-size: 12px;
    }
  </style>
</head>

<body>
  <table cellpadding="0" cellspacing="0" style="width:100vw;">
    <tr>
      <td style="width:80%;padding:20px;">
        <img src="/Images/axa_logo_solid.png" alt="AXA Logo" width="33" />
      </td>
    </tr>
  </table>
  <section class="main">
    <h1>Something happen...</h1>
    <p>
      An error has occured. We apologise for the inconvinience.<br />
      <br />
      The error occured at <strong>${this.date.toISOString()}</strong><br />
      <strong>Code: ${this.statusCode}</strong><br />
      <strong>Message: ${this.message}</strong><br />
      <strong>Trace: </strong>
    </p>
    <p>
      Our team is informed of this problem and works now to resolve it, as soon as possible.<br /><br />
      <a href="/" class="button">Back to the application</a>
    </p>
  </section>
</body>

</html>
`;
    document.write(content);
    const errorDetails = {
      name: this.name,
      statusCode: this.statusCode,
      message: this.message,
      trace: JSON.stringify(this.trace),
      date: this.date
    }
    sendErrorToBackEnd(errorDetails);
  }
}

export default APIError;

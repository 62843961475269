const fr = {
  disclaimer: {
    explanations: [
      'Cher client,',
      "La déclaration d'incident se fait en deux étapes distinctes.",
      "La première se concentre sur les informations de l'incident, ce qu'il s'est passé, quand et où.",
      'Si cette partie est validée par notre système, vous pourrez accéder à une seconde partie via un email de confirmation.',
      "Elle se concentrera sur les détails de l'incident : photos, documents etc...",
      'Merci de prendre note de ces informations avant de commencer la saisie du formulaire.'
    ]
  },
  lang: {
    en: 'Anglais / en',
    fr: 'Français / fr',
    country_fr: 'France',
    country_en: 'United-Kingdom'
  },
  errors: {
    noIncidentLocation: "Aucune position d'incident n'a été définie !",
    fieldIsMandatory: 'Le champ est obligatoire.',
    geolocationFromNavigatorIsNotAvailable:
      "Votre navigateur n'est pas autorisé à déterminer votre position. Merci d'utiliser le champ texte ci-dessus.",
    resource_not_found: ['Not Found']
  },
  selectDefaultOption: {
    label: 'Merci de faire un choix',
    yes: 'Oui',
    no: 'Non'
  },
  defaultValue: {
    licencePlate: 'AB123CD'
  },
  formSteps: {
    step0: 'Déclaration',
    step1: "Que s'est-il passé ?",
    step2: "Quand cela s'est-il passé ?",
    step3: "Où cela s'est-il passé ?",
    step4: 'Récapitulons...',
    step5: 'Quelques détails en plus...',
    'step-1': 'Processing of your claim cannot be completed',
    'step-2': 'Unknown Error'
  },
  navigation: {
    nextStep: 'Etape suivante',
    previousStep: 'Etape précédente',
    validateFirstStep: 'Valider la déclaration de sinistre',
    submitForm: "Valider l'ensemble de mes informations"
  },
  incidentType: {
    label: "Qu'est-il arrivé au véhicule ?",
    theft: 'Vol',
    accident: 'Accident',
    fire: 'Incendie'
  },
  incidentDescription: {
    label: "Décrivez ce qu'il s'est passé",
    placeholder: 'Merci de donner le plus de détails possible...'
  },
  injuryIndicator: {
    label: "Est-ce qu'une personne a été bléssée ?",
    false: 'Non',
    true: 'Oui'
  },
  incidentSubType: {
    label: "L'accident a-t-il impliqué un tiers ?",
    withThirdParty: 'Oui',
    withoutThirdParty: 'Non'
  },
  driverVehiclePlateNumber: {
    label: "Plaque d'immatriculation du conducteur",
    example: 'Exemple: AZ123BG'
  },
  dateOfTheIncident: {
    date: "Date de l'incident",
    dateExample: 'Exemple: jj/mm/aaaa',
    time: "Heure de l'incident",
    timeExample: 'Exemple: 09h15',
    at: 'à'
  },
  map: {
    search: 'Chercher une adresse ...',
    loading: 'Chargement de la localisation actuelle...',
    explanations:
      'Note : Vous pouvez cliquer longtemps sur la carte pour ajouter un marqueur de position, ou saisir une adresse dans le champ texte.'
  },
  result: {
    modifyThisStep: 'Corriger ces informations',
    explanations:
      "Veuillez vérifier les données que vous avez saisie.<br />Si une donnée s'avère éronnée, merci de la corriger à l'étape correspondante."
  },
  driverToRepairIndicator: {
    label: 'Avez vous pris en charge les réparations ?',
    true: 'Oui',
    false: 'Non'
  },
  carImmobilizedDueToAccident: {
    label: "Est-ce que le véhicule s'est vu immobilisé à cause de l'accident ?",
    true: 'Oui',
    false: 'Non'
  },
  Step5: {
    step1: 'Merci de répondre aux questions suivantes'
  },
  files: {
    label: 'Photos du véhicules endomagé',
    placeholder:
      'Merci de sélectionner au moins une photo du véhicule endomagé',
    accidentDate: 'Accident Date',
    repairStartDate: 'Repair Start Date',
    returnDate: 'Return date of the vehicle',
    repairEndDate: 'Repair End date',
    newCarRegistrationDate: 'New car registration',
    carInvoiceOrCarQuote: 'Car invoice',
    insuranceDocumentForClaim: 'Insurance Document for Claim',
    invoiceFeesFromLeaseCompany: 'Invoice Fees of Lease Company',
    policeReport: 'Police repport',
    MOT: 'Mot'
  },
  notEligible: {
    explanations:
      "Desolé mais vous n'êtes pas éligible. Pour plus d'information, n'hésitez pas à contacter votre fourniseur."
  }
};

export default fr;

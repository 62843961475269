import sendErrorToBackEnd from './logErrors'

const defaultPostalAddress = {
  street_number: '',
  route: '',
  locality: '',
  postal_code: '',
  country: { code: 'GB', name: 'United Kingdom' },
};

const Common = {
  /**
   * getURLParameters
   * Get Query String parameters from the current URL
   * @return { Array } Array made of key : value as key is the parameter name, value its... value.
   */
  getURLParameters() {
    if (window.location.search.length === 0) {
      return [];
    }
    const splitedParameters = window.location.search.replace(/\?/, '').split('&');
    if (splitedParameters[0].length > 0) {
      const parameters = {};
      for (let i = 0; i < splitedParameters.length; i++) {
        const string = splitedParameters[i];
        const a = string.split(/=/);
        parameters[a[0]] = encodeURIComponent(a[1]);
      }
      return parameters;
    } else {
      return [];
    }
  },

  /**
   * loadClaim
   * Load data from claim
   * @return { Object } Return a formated Object
   */
  async loadClaim() {
    // First step, load URL Querystring parameters
    const qp = Common.getURLParameters();
    // Prepare headers for the first call to the API GET:claim/{claimid}/to-resume
    const headers = new Headers();
    headers.append('Content-Type', 'text/plain');
    headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
    const options = {
      method: 'GET',
      headers,
    };
    const decodedClaimId = atob(qp.claimid);
    const url = `${window.Config.APIEndpoint}/claim/${decodedClaimId}/to-resume`;
    try {
      // Fetching the API Url
      const fetchResult = await fetch(url, options);

      if (fetchResult.status >= 400) {
        const errorContent = await fetchResult.json();
        return {
          ...errorContent,
          error: 'ok',
          statusCode: fetchResult.status,
          statusText: fetchResult.statusText,
        };
      }
      const jsonResult = await fetchResult.json();
      // From the result, we check and gather the value from policyholder postal address
      // This value is from Uber, so we admit that it can be empty. If so, we replace the
      // street name, by Downing Street value
      const address =
        jsonResult.policyholder && jsonResult.policyholder.postalAddress
          ? jsonResult.policyholder.postalAddress.join(' ')
          : '';
      // Set default values
      jsonResult.policyholder.postalAddress = defaultPostalAddress;
      // Checking if the postalAddress from the jsonResult is a proper value (not empty value)
      if (address.length > 0) {
        let computedAddress = await Common.populateAddress(address);
        if (computedAddress.country.code !== 'GB') {
          computedAddress = defaultPostalAddress;
        }
        jsonResult.policyholder.postalAddress = computedAddress;
        //console.log('HERE !', computedAddress);
      }
      return jsonResult;
    } catch (error) {
      return undefined;
    }
  },

  async populateAddress(address) {
    // Starting to collect evidences about a potential error
    let errorDetail = {
      in: 'Utils/Common/populateAddress'
    };
    if (typeof address === 'string') {
      const postalAddress = {};

      // Putting in place headers
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
      headers.append('Content-Type', 'text/plain');
      try {
        const f = await fetch(window.Config.GoogleMapAPIEndpoint, {
          method: 'POST',
          body: JSON.stringify({
            address: encodeURIComponent(address),
          }),
          headers,
        });
        const GMapResult = await f.json();
        if (f.status >= 400) {
          errorDetail = {
            ...errorDetail,
            log: 'The request to Google map is incorrect',
            GMapResult
          };
          sendErrorToBackEnd(errorDetail);
          return {
            ...GMapResult,
            error: 'ok',
            statusCode: f.status,
            statusText: f.statusText,
          };
        }
        if (GMapResult.status === 'ZERO_RESULTS') {
          return defaultPostalAddress;
        }
        ['street_number', 'route', 'country', 'postal_town', 'municipality', 'locality', 'postal_code'].forEach(
          (type) => {
            // If the address is valid, we can continue through the process
            if (GMapResult.results && GMapResult.results[0]) {
              // Looping over the keys of the previous object, as it appears that Google Map can
              // And then, looping over address compotents from google map
              GMapResult.results[0].address_components.forEach((component) => {
                // If in the current component [types] the current key is appearing, we store
                // Corresponding value found, we store long_name from GMap as the proper value
                if (component.types.indexOf(type) !== -1) {
                  if (type === 'country') {
                    postalAddress[type] = {
                      name: component.long_name,
                      code: component.short_name,
                    };
                  } else {
                    postalAddress[type] = component.long_name;
                  }
                }
              });
            }
          }
        );
        return postalAddress;
      } catch (error) {
        errorDetail = {
          ...errorDetail,
          log: 'The address cannot be populated',
          error
        };
        sendErrorToBackEnd(errorDetail);
      }
    } else {
      errorDetail = {
        ...errorDetail,
        log: `The address seems to be incorrect. The address parameter must be a String, instead got ${typeof address}`
      }
      sendErrorToBackEnd(errorDetail);
      throw new Error(
        `Utils/Common/populateAddress : address parameter must be a String, instead got ${typeof address}`
      );
    }
  },
  getCountryName(countryCode) {},
  getCountryCode(countryName) {},
  isoCountryCode: [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: 'Aland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua And Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BA', label: 'Bosnia And Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, Democratic Republic' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: "Cote D'Ivoire" },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island & Mcdonald Islands' },
    { value: 'VA', label: 'Holy See (Vatican City State)' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran, Islamic Republic Of' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle Of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KR', label: 'Korea' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: "Lao People's Democratic Republic" },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libyan Arab Jamahiriya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'Macedonia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States Of' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'AN', label: 'Netherlands Antilles' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestinian Territory, Occupied' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barthelemy' },
    { value: 'SH', label: 'Saint Helena' },
    { value: 'KN', label: 'Saint Kitts And Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre And Miquelon' },
    { value: 'VC', label: 'Saint Vincent And Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome And Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia And Sandwich Isl.' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard And Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad And Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks And Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'VG', label: 'Virgin Islands, British' },
    { value: 'VI', label: 'Virgin Islands, U.S.' },
    { value: 'WF', label: 'Wallis And Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
  ],
};

export default Common;

const Documents = {
  initialState: {
    // Date
    visibleDates: [],
    dates: {
      vehicleUnavailabilityStartDate: { type: 'date', visible: false },
      accidentDate: { type: 'date', visible: false },
      repairStartDate: { type: 'date', visible: false },
      returnDate: { type: 'date', visible: false },
      repairEndDate: { type: 'date', visible: false },
      newCarRegistrationDate: { type: 'date', visible: false }
    },
    // Files
    visibleFiles: [],
    files: {
      incidentScenePictures: { type: 'file', visible: false, mandatory: false},
      damagedVehiclePictures: { type: 'file', visible: false, mandatory: true },
      vehicleRepairProof: { type: 'file', visible: false, mandatory: true },
      vehicleReplacementProof: { type: 'file', visible: false, mandatory: true },
      totalLossProof: { type: 'file', visible: false, mandatory: true }
    }
  },
  rules: {
    '110': [
      'vehicleUnavailabilityStartDate',
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleReplacementProof'
    ],
    '111': [
      'vehicleUnavailabilityStartDate',
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleRepairProof'
    ],
    '100': [
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleReplacementProof'
    ],
    '101': [
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleRepairProof'
    ],
    '010': [
      'vehicleUnavailabilityStartDate',
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleReplacementProof'
    ],
    '011': [
      'vehicleUnavailabilityStartDate',
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleRepairProof'
    ],
    '012': [
      'vehicleUnavailabilityStartDate',
      'damagedVehiclePictures',
      'incidentScenePictures',
      'totalLossProof'
    ],
    '000': [
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleReplacementProof'
    ],
    '001': [
      'damagedVehiclePictures',
      'incidentScenePictures',
      'vehicleRepairProof'
    ],
    '002': [
      'damagedVehiclePictures',
      'incidentScenePictures',
      'totalLossProof'
    ]
  }
};

export default Documents;

const sendErrorToBackEnd = async error => {
  const url = `${window.Config.APIFrontendEndpoint}/logErrors`;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(error),
      headers,
    });
    // There is nothing else to do. We already sent the error to the backend for logging.
  } catch (e) {
    // We're already sending an error for logging purposes. If even this is failing I don't see what I can do...
  }
}

export default sendErrorToBackEnd;

import moment from 'moment-timezone';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import Steps from '../Steps';
import Common from '../../Utils/Common';
import WhereUtils from '../../Utils/Where';
import Navigation, { checkForErrors } from '../../Utils/Navigation';
import UkModulusChecking from 'uk-modulus-checking';

import EXIF from 'exif-js';

import './Form.scss';
import Spinner from '../Common/SpinnerComponent';

const Components = {
  Portal: React.lazy(() => import('../Steps/Portal')),
  Disclaimer: React.lazy(() => import('../Steps/Disclaimer')),
  What: React.lazy(() => import('../Steps/What')),
  When: React.lazy(() => import('../Steps/When')),
  Where: React.lazy(() => import('../Steps/Where')),
  Result: React.lazy(() => import('../Steps/Result')),
  Validation: React.lazy(() => import('../Steps/Validation')),
  Conclusion: React.lazy(() => import('../Steps/Conclusion')),
  PersonnalData: React.lazy(() => import('../Steps/PersonnalData')),
  NotCovered: React.lazy(() => import('../Steps/NotCovered')),
  ErrorComponent: React.lazy(() => import('../Steps/Error')),
  Error404Component: React.lazy(() => import('../Steps/Error404')),
  Details: React.lazy(() => import('../Steps/Details')),
  Loyalty: React.lazy(() => import('../Steps/LoyaltyLevel')),
  ThirdPartyInformation: React.lazy(() => import('../Steps/ThirdPartyInformation')),
  Files: React.lazy(() => import('../Steps/Files')),
  faq: React.lazy(() => import('../Steps/FAQ')),
  privacyPolicy: React.lazy(() => import('../Steps/PrivacyPolicy')),
  LoadClaim: React.lazy(() => import('../Steps/LoadClaim')),
  ContinueClaim: React.lazy(() => import('../Steps/ContinueClaim')),
  Signout: React.lazy(() => import('../Steps/Signout')),
  FileUploader: React.lazy(() => import('../DetailsComponents/Components/FileUploader')),
};

moment.tz.setDefault(moment.tz.guess());

const ns = {
  accountNumber: '',
  sortCode: '',
};

/**
 * Form class
 * used to managed all the form stuff
 */
class Form extends React.Component {
  constructor(props) {
    super(props);
    props.initForm();
  }

  render() {
    const TagName = Components[Steps[this.props.currentStep].Component];
    return (
      <section className="Form-Section section">
        <Suspense fallback={<Spinner />}>
          <TagName {...this.props} />
        </Suspense>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Form,
    Literals: state.Literals,
    currentLang: state.Literals.currentLang,
    currentPageTitle: getCurrentPageTitle(state.Form.currentStep),
  };
};

function getCurrentPageTitle(index) {
  return `step${index}`;
}

const mapDispatchToProps = (dispatch, getState) => ({
  initForm: (event) => {
    const currentStep = Navigation.getPage();
    dispatch({
      type: 'initForm',
      currentStep,
    });
  },
  copyPaste: (event) => {
    const item = event.target;
    const value = item.getAttribute('tocopy');
    if (value !== null) {
      const ta = document.createElement('textarea');
      ta.value = value;
      document.querySelector('body').appendChild(ta);
      ta.select();
      document.execCommand('copy');
      document.querySelector('body').removeChild(ta);
      document.querySelector('#claim-id-to-resume').value = value;
    }
  },
  gotoStep: (currentStep, way) => {
    console.log('Form : way should be a text', way);
    if (!!window.gmap) {
      window.gmap = undefined;
      console.log('Gmap has been killed ! BANG');
    }
    dispatch({ type: 'gotoStep', currentStep, way });
  },
  saveFilesAndGotoStep: (currentStep, way) => {
    dispatch({ type: 'saveFilesAndGotoStep', currentStep, way });
  },
  emptyCurrentFilesAndGotoStep: (currentStep, way) => {
    dispatch({ type: 'emptyCurrentFilesAndGotoStep', currentStep, way });
  },
  nextStep: (event) => {
    dispatch({ type: 'nextStep', event });
  },
  setCurrentFileName: (fileName) => {
    dispatch({ type: 'setCurrentFileName', fileName });
  },
  handleUserAcceptance: (domElement) => {
    dispatch({
      type: domElement.matches(':checked') ? 'unStuckNavigation' : 'stuckNavigation',
    });
  },
  handleStuckNavigation: () => {
    dispatch({
      type: 'stuckNavigation',
    });
  },
  handleUnStuckNavigation: () => {
    dispatch({
      type: 'unStuckNavigation',
    });
  },
  handleStuckGoBack: () => {
    dispatch({
      type: 'stuckGoBack',
    });
  },
  handleUnStuckGoBack: () => {
    dispatch({
      type: 'unStuckGoBack',
    });
  },
  handleChangeIncidentType: (element) => {
    dispatch({
      type: 'IncidentIsAnAccident',
      status: element,
    });
  },
  cantUpdatePlacesFromNavigator: (error) => {
    dispatch({ type: 'CantUpdatePlacesFromNavigator', error });
  },
  registerMap: (map, state) => {
    dispatch({ type: 'loadingPlace' });
    dispatch({ type: 'stuckNavigation' });
    window.gmap = window.gmap || map;
    if (navigator.geolocation && typeof state.data.incidentLocation === 'undefined') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          WhereUtils.getDataFromGMaps(latitude, longitude, (res) => {
            if (res.error) {
              const error = res;
              dispatch({ type: 'networkError', error });
              return false;
            }
            dispatch({ type: 'UpdatePlaces', results: res.results });
          });
        },
        (error) => {
          dispatch({ type: 'CantUpdatePlacesFromNavigator', error });
        }
      );
    } else {
      let latitude = null;
      let longitude = null;
      if (
        state.data &&
        state.data.incidentLocation &&
        state.data.incidentLocation.globalPositionAddress &&
        state.data.incidentLocation.globalPositionAddress.longitude
      ) {
        latitude = state.data.incidentLocation.globalPositionAddress.latitude;
        longitude = state.data.incidentLocation.globalPositionAddress.longitude;
      } else {
        latitude = window.gmap.center.lat();
        longitude = window.gmap.center.lng();
      }
      WhereUtils.getDataFromGMaps(latitude, longitude, (res) => {
        if (res.error) {
          const error = res;
          dispatch({ type: 'networkError', error });
          return false;
        }
        dispatch({ type: 'UpdatePlaces', results: res.results });
      });
    }
  },
  registerAutocomplete: (autocomplete, state) => {
    window.autocomplete = autocomplete;
    window.autocomplete.input = document.getElementById('gh-Form-Where-autocomplete-input');
  },
  handleUnloadMap: (event) => {
    dispatch({ type: 'handleUnloadMap', event });
  },
  handleMouseDown: (event) => {
    dispatch({ type: 'FingerDown', event });
  },
  handleMouseUp: (event) => {
    dispatch({ type: 'FingerUp', event });
  },
  handleDragStart: (event) => {
    dispatch({ type: 'DragStart', event });
  },
  handleDragEnd: (event) => {
    dispatch({ type: 'DragEnd' });
  },
  handleZoomChanged: (event) => {
    dispatch({ type: 'ZoomChanged', event });
  },
  handleMouseMove: (event) => {
    dispatch({ type: 'MouseMove', event });
  },
  handleIdle: () => {
    dispatch({ type: 'Idle' });
  },
  handleDblClick: (event) => {
    dispatch({ type: 'Idle' });
  },
  handlePlaceUpdate: (state) => {
    dispatch({ type: 'loadingPlace' });
    const place = window.autocomplete.getPlace();
    if (!!place && !!place.geometry) {
      const { lat, lng } = place.geometry.location;
      WhereUtils.getDataFromGMaps(lat(), lng(), (res) => {
        if (res.error) {
          const error = res;
          dispatch({ type: 'networkError', error });
          return false;
        }
        dispatch({ type: 'UpdatePlaces', results: res.results });
      });
    } else {
      dispatch({ type: 'NoLocationFound' });
      dispatch({ type: 'placeLoaded' });
    }
  },
  handleLocalisation: (state) => {
    dispatch({ type: 'loadingPlace' });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          WhereUtils.getDataFromGMaps(latitude, longitude, (res) => {
            if (res.error) {
              const error = res;
              dispatch({ type: 'networkError', error });
              return false;
            }
            dispatch({ type: 'UpdatePlaces', results: res.results });
          });
        },
        (error) => {
          dispatch({ type: 'cantUpdatePlacesFromNavigator', error });
        }
      );
    } else {
      dispatch({ type: 'cantUpdatePlacesFromNavigator' });
    }
  },
  handleEraseLocation: (state) => {
    dispatch({ type: 'eraseLocation' });
  },
  displayDriverToRepairIndicator: () => {
    const totalLossIndicator = getValue('totalLossIndicator') ? toBoolean(getValue('totalLossIndicator').value) : false;
    dispatch({
      type: 'TriggerDisplayDriverToRepairIndicator',
      totalLossIndicator,
    });
  },
  displayIncidentDates: (event, state) => {
    const incidentSubType = getValue('incidentSubType') ? toBoolean(getValue('incidentSubType').value) : false;
    const driverToRepairIndicator = getValue('driverToRepairIndicator')
      ? toBoolean(getValue('driverToRepairIndicator').value)
      : false;
    const startedRepairIndicator = getValue('startedRepairIndicator')
      ? toBoolean(getValue('startedRepairIndicator').value)
      : false;
    const code1 = Number(incidentSubType);
    const code2 = Number(startedRepairIndicator);
    const code3 = Number(driverToRepairIndicator);

    const triggerCode = `${code1}${code2}${code3}`;
    const options = {
      type: 'TriggerDisplayDate',
      triggerCode,
      driverToRepairIndicator,
    };
    if (toBoolean(driverToRepairIndicator) === false) {
      delete state.data.startedRepairIndicator;
    } else {
      options.startedRepairIndicator = startedRepairIndicator;
    }
    dispatch(options);
  },
  handledStuckUpload() {
    dispatch({
      type: 'stuckUpload',
    });
  },
  handleUnStuckUpload() {
    dispatch({
      type: 'unStuckUpload',
    });
  },
  addNewFileToCue: async (name, state) => {
    if (!window.Config.filesToUpload[name]) window.Config.filesToUpload[name] = [];
    const index = window.Config.filesToUpload[name].length;
    if (state[`File_${name}`] && state[`File_${name}`] === 'mandatory') {
      window.Config.filesToUpload[name].mandatory = true;
    }

    //console.log('File name', name);
    if (name === 'vehicleRepairProof') {
      dispatch({ type: 'removeVehicleReplacementProof' });
      dispatch({ type: 'removeTotalLossProof' });
    }

    if (name === 'vehicleReplacementProof') {
      dispatch({ type: 'removeVehicleRepairProof' });
      dispatch({ type: 'removeTotalLossProof' });
    }

    if (name === 'totalLossProof') {
      dispatch({ type: 'removeVehicleReplacementProof' });
      dispatch({ type: 'removeVehicleRepairProof' });
    }

    //console.log('State after update');

    const file = document.getElementById(`gh-${name}`);
    if (file.files && file.files[0]) {
      dispatch({ type: 'loadFile', status: true });
      if (file.files.length > 0) {
        async function FormatFile(file, domName) {
          const splitFileName = file.name.split('.');
          const extention = `.${splitFileName.pop()}`;
          const filename = `${encodeURIComponent(splitFileName[0])}_${index}${extention}`;
          try {
            const resource = await readFileAsync(file);
            const response = {
              filename,
              name: filename,
              resource,
              creating: false,
              size: updateSize(file),
              extention,
              exif: await getExif(file),
              domName,
            };
            console.log(response);
            return response;
          } catch (e) {
            return e;
          }
        }
        async function getExif(image) {
          return new Promise((resolve, reject) => {
            EXIF.getData(image, function() {
              const data = EXIF.getAllTags(this);
              resolve({ ...data });
            });
          });
        }
        const getFile = async (item) => await FormatFile(item, name);
        const getFiles = async (files) => await Promise.all(files.map((file) => getFile(file)));
        window.Config.filesToUpload[name] = window.Config.filesToUpload[name].concat(await getFiles([...file.files]));
        dispatch({ type: 'loadFile', status: false });
        dispatch({
          type: 'displayThumbnail',
          filesToUpload: {
            [name]: window.Config.filesToUpload[name],
          },
        });
      }
    }
    return false;
  },
  removeFileFromCue: (event, index, name, file) => {
    dispatch({
      type: 'removeFile',
      index,
      name,
    });
    const size = parseFloat(file.size.fileNum.split(' ')[0]) / 1e6;
    if (size > 24.99) {
      dispatch({
        type: 'unStuckGoBack',
      });
      dispatch({
        type: 'unStuckNavigation',
      });
      dispatch({
        type: 'unStuckUpload',
      });
    }
  },
  handleUpload: (name, mandatory = false) => {
    dispatch({
      type: 'gotoStepUpload',
      name,
      mandatory,
    });
  },
  validateModulusChecking: () => {
    validateAccountAndSortNumber(dispatch);
  },
  validateAccountNumber: (e, props) => {
    const name = props[props.context].name;
    let accountNumber = [...document.querySelector(`[name="${name}"]`).value];
    accountNumber = accountNumber.filter((c) => {
      return c.match(/[0-9]{1}/g);
    });
    ns.accountNumber = accountNumber.join('');
    document.querySelector(`[name="${name}"]`).value = ns.accountNumber;
    validateAccountAndSortNumber(dispatch);
  },
  validateSortCode: (e, props) => {
    const name = props[props.context].name;
    const sortCode = document.querySelector(`[name="${name}"]`).value;
    ns.sortCode = sortCode;
    validateAccountAndSortNumber(dispatch);
  },
  validateClaimStep1Id: (element, props) => {
    const errors = checkForErrors(element);
    if (errors[element.getAttribute('name')]) {
      if (errors[element.getAttribute('name')].length > 0) {
        dispatch({
          type: 'sendErrors',
          errors: checkForErrors(element),
        });
        dispatch({
          type: 'stuckNavigation',
        });
      } else {
        dispatch({
          type: 'removeError',
        });
        dispatch({
          type: 'unStuckNavigation',
        });
      }
    }
  },
  throwError: (options) => {
    dispatch({ type: 'throwError', ...options });
  },
  displayDateRepairStarted: (event, props, status) => {
    dispatch({ type: 'saveForm' });
  },
  saveForm: (formName) => {
    dispatch({ type: 'saveForm', formName });
  },
  sendError: (errorName) => {
    dispatch({ type: 'sendError', errorName });
  },
  removeError: () => {
    dispatch({ type: 'removeError' });
  },
  checkForErrors: (event) => {
    dispatch({
      type: 'sendErrors',
      errors: checkForErrors(event.target),
    });
  },
  resetDisplayDate: () => {
    const incidentSubType = getValue('incidentSubType') ? Number(getValue('incidentSubType').value === 'true') : 0;
    const driverToRepairIndicator = getValue('driverToRepairIndicator')
      ? Number(getValue('driverToRepairIndicator').value === 'true')
      : 0;
    const startedRepairIndicator = getValue('startedRepairIndicator')
      ? Number(getValue('startedRepairIndicator').value === 'true')
      : 0;

    const triggerCode = `${incidentSubType}${startedRepairIndicator}${driverToRepairIndicator}`;
    dispatch({
      type: 'TriggerDisplayDate',
      triggerCode,
      driverToRepairIndicator: Boolean(driverToRepairIndicator),
      startedRepairIndicator: Boolean(startedRepairIndicator),
    });
  },
  claimLoaded: (claim) => {
    const driver = window.Config.Driver;
    dispatch({ type: 'ClaimLoaded', claim, driver });
  },
  notCovered: (claim) => {
    dispatch({ type: 'notCovered', claim });
  },
  networkError: (error) => {
    dispatch({ type: 'networkError', error });
  },
  notFound: (error) => {
    dispatch({ type: 'notFound', error });
  },
  formatDriverLicencePlate: (field, state) => {
    let plateNumber = field.value;
    plateNumber = plateNumber.replace(/\s/g, '').toUpperCase();
    field.value = plateNumber;
    let errors = [];
    const regExp = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)/;
    if (plateNumber.match(regExp) === null) {
      errors = [{ type: 'format' }];
    }
    dispatch({
      type: 'sendErrors',
      errors: {
        ...state.errors,
        [state.currentStep]: {
          ...state.errors[state.currentStep],
          [field.name]: errors,
        },
      },
    });
  },
  async formatPostalAddress(state) {
    if (state.claim && state.claim.policyholder && state.claim.policyholder.postalAddress.length) {
      const postalAddress = await Common.populateAddress(state.claim.policyholder.postalAddress.join(' '));
      dispatch({
        type: 'FormatAddressFromAPI',
        postalAddress,
      });
    }
  },
  validateField(field, props) {
    dispatch({
      type: 'validateField',
      field,
    });
  },
  limitTo500Chars(field, state) {
    const value = field.value;
    if (value.length >= 501) {
      dispatch({
        type: 'sendErrors',
        errors: {
          ...state.errors,
          [state.currentStep]: {
            ...state.errors[state.currentStep],
            [field.name]: [{ type: 'size500' }],
          },
        },
      });
    }
  },
  validateDate(element, state) {
    const isDateIsInTheFuture = checkDateIfItsInTheFuture({
      date: 'dateOfTheIncident-date',
      time: 'dateOfTheIncident-time',
    });
    if (isDateIsInTheFuture === true) {
      dispatch({
        type: 'stuckNavigation',
      });
      dispatch({
        type: 'sendErrors',
        errors: {
          ...state.errors,
          [state.currentStep]: {
            ...state.errors[state.currentStep],
            'dateOfTheIncident-time': [{ type: 'dateIsInTheFuture' }],
            'dateOfTheIncident-date': [{ type: 'dateIsInTheFuture' }],
          },
        },
      });
    } else {
      dispatch({
        type: 'removeError',
      });
      dispatch({
        type: 'unStuckNavigation',
      });
    }
  },
  validateRepairDate(element, state) {
    let errors = {};
    if (
      checkDifferenceBetweenTwoDates({
        date1: 'repairStartDate',
        date2: 'repairEndDate',
      })
    ) {
      errors = {
        ...errors,
        repairEndDate: [{ type: 'dateMustHaveOneDayOfDifference' }],
      };
    }
    if (
      checkDateIfItsInTheFuture({
        date: 'repairStartDate',
      })
    ) {
      errors = {
        ...errors,
        repairStartDate: [{ type: 'dateIsInTheFuture' }],
      };
    }

    if (Object.keys(errors).length > 0) {
      dispatch({
        type: 'sendErrors',
        errors: {
          ...state.errors,
          [state.currentStep]: errors,
        },
      });
      dispatch({
        type: 'stuckNavigation',
      });
    } else {
      dispatch({
        type: 'unStuckNavigation',
      });
      dispatch({
        type: 'removeError',
      });
    }
  },
  validateReplacementDate(element, state) {
    let errors = {};
    if (
      checkDifferenceBetweenTwoDates({
        date1: 'vehicleUnavailabilityStartDate',
        date2: 'vehicleReturnToPlatformDate',
      })
    ) {
      errors = {
        ...errors,
        vehicleReturnToPlatformDate: [{ type: 'dateMustHaveOneDayOfDifference' }],
      };
    }
    if (
      checkDateIfItsInTheFuture({
        date: 'vehicleUnavailabilityStartDate',
      })
    ) {
      errors = {
        ...errors,
        vehicleUnavailabilityStartDate: [{ type: 'dateIsInTheFuture' }],
      };
    }

    if (Object.keys(errors).length > 0) {
      dispatch({
        type: 'sendErrors',
        errors: {
          ...state.errors,
          [state.currentStep]: errors,
        },
      });
      dispatch({
        type: 'stuckNavigation',
      });
    } else {
      dispatch({
        type: 'unStuckNavigation',
      });
      dispatch({
        type: 'removeError',
      });
    }
  },
});

function checkDateIfItsInTheFuture({ date, time }) {
  const dateValue = document.querySelector(`[name="${date}"]`).value;
  let timeValue = '';
  if (time) {
    timeValue = ' ' + document.querySelector(`[name="${time}"]`).value;
  }
  const selectedDate = moment(`${dateValue}${timeValue}`);
  if (selectedDate.isAfter(moment())) {
    return true;
  } else {
    return false;
  }
}

function checkDifferenceBetweenTwoDates({ date1, date2 }) {
  const startDate = moment(document.querySelector(`[name="${date1}"]`).value);
  const endDate = moment(document.querySelector(`[name="${date2}"]`).value);
  const diff = moment.duration(endDate.diff(startDate));
  if (diff.days() < 1) {
    return true;
  } else {
    return false;
  }
}

function validateAccountAndSortNumber(dispatch) {
  if (ns.accountNumber && ns.sortCode) {
    const result = new UkModulusChecking({
      accountNumber: ns.accountNumber,
      sortCode: ns.sortCode,
    });
    const isValid = result.isValid();
    dispatch({
      type: 'validateAccountAndSortCode',
      isValid,
    });
  }
}

function getValue(name) {
  return document.querySelector(`input[name="${name}"]:checked`);
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

function updateSize(file) {
  let size = file.size + ' bytes';
  let output = size;
  // optional code for multiples approximation
  for (
    let aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], nMultiple = 0, nApprox = file.size / 1024;
    nApprox > 1;
    nApprox /= 1024, nMultiple++
  ) {
    output = `${nApprox.toFixed(2)}${aMultiples[nMultiple]}`;
  }
  // end of optional code
  return {
    fileNum: size,
    fileSize: output,
  };
}

function toBoolean(value) {
  if (value === 'true') value = true;
  if (value === 'false') value = false;
  return value;
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Steps from '../Steps';
import Utils, { validateForm, gatherData } from '../../Utils/Navigation';
import APIError from '../../Utils/Errors';
import Common from '../../Utils/Common';
import Spinner from '../Common/SpinnerComponent';
import './Navigation.scss';
import BannerComponent from '../Common/BannerComponent';
import Cookie from '../../Utils/Cookie';
import sendErrorToBackEnd from '../../Utils/logErrors'

moment.tz.setDefault(moment.tz.guess());

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    const stuckedClassName = this.props.Form.navigationStuck || this.props.Form.loadingNavigation ? 'Button-Stuck' : '';
    const step = Steps[this.props.Form.currentStep];
    let nextStep = undefined;
    if (step.next && typeof step.next.step === 'function') {
      nextStep = step.next.step(this.props);
      step.next.step = nextStep;
    } else {
      nextStep = step.next && step.next.step;
    }
    let previousStep = undefined;
    if (step.previous && typeof step.previous.step === 'function') {
      previousStep = step.previous.step(this.props);
      step.previous.step = previousStep;
    } else {
      previousStep = step.previous && step.previous.step;
    }
    //console.log(step, step.previous);
    return step.noNavigation ? null : (
      <section className="Navigation section">
        {this.props.Form.loadingNavigation && step.displayLoader ? (
          <span className="informationMessage">
            <Spinner /> {this.props.Literals.result.loading}
          </span>
        ) : (
          ''
        )}
        {nextStep ? (
          <button
            id={`gh-button-next-${nextStep}`}
            onClick={(event) =>
              !this.props.Form.navigationStuck ? this.props[step.next.onClick](nextStep, 'next', this.props) : false
            }
            className={`${step.next.className} ${stuckedClassName}`}
          >
            {this.props.Literals.navigation[step.next.label]}
          </button>
        ) : (
          ''
        )}
        {typeof previousStep !== 'undefined' ? (
          <button
            id={`gh-button-previous-${previousStep}`}
            onClick={(event) =>
              !this.props.Form.loadingNavigation && !this.props.Form.goBackStuck
                ? this.props[step.previous.onClick](previousStep, 'previous', this.props)
                : false
            }
            className={`${step.previous.className} ${this.props.Form.loadingNavigation ? stuckedClassName : ''} ${
              this.props.Form.goBackStuck ? stuckedClassName : ''
            }`}
          >
            {this.props.Literals.navigation[step.previous.label]}
          </button>
        ) : (
          ''
        )}
        {Cookie.read('pendingClaim') &&
        this.props.steps &&
        this.props.steps[this.props.currentStep].stepIndex === 1 &&
        (window.sessionStorage.getItem('notNow') === null || window.sessionStorage.getItem('notNow') === 'false') ? (
          <BannerComponent {...this.props} />
        ) : (
          ''
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    ...state.Form,
    ...state.Literals,
  };
};

const mapDispatchToProps = (dispatch, getState) => ({
  closeBanner: () => {
    window.sessionStorage.setItem('notNow', 'true');
    dispatch({
      type: 'hideBanner',
    });
  },
  validateClaimStep1Id: () => {
    dispatch({
      type: 'removeError',
    });
    const claimIdDom = document.getElementById('gh-claim-id-to-resume');
    if (claimIdDom && claimIdDom.value.length > 0) {
      const eci = btoa(claimIdDom.value);
      window.location.href = `./?p=LOAD_CLAIM&claimid=${eci}`;
    } else {
      dispatch({
        type: 'sendErrors',
        errors: {
          'claim-id-to-resume': [
            {
              type: 'mandatory',
            },
          ],
        },
      });
    }
  },
  copyPaste: (event) => {
    const item = event.target;
    if (item.getAttribute('tocopy')) {
    }
  },
  handleStuckNavigation: () => {
    dispatch({
      type: 'stuckNavigation',
    });
  },
  handleUnStuckNavigation: () => {
    dispatch({
      type: 'unStuckNavigation',
    });
  },
  handleStuckGoBack: () => {
    dispatch({
      type: 'stuckGoBack',
    });
  },
  handleUnStuckGoBack: () => {
    dispatch({
      type: 'unStuckGoBack',
    });
  },
  upload: () => {
    dispatch({
      type: 'upload',
    });
  },
  gotoStep: (currentStep, way) => {
    if (!!window.gmap) {
      window.gmap = undefined;
    }
    dispatch({
      type: 'gotoStep',
      currentStep,
      way,
    });
  },
  emptyCurrentFilesAndGotoStep: (currentStep, way) => {
    dispatch({ type: 'emptyCurrentFilesAndGotoStep', currentStep, way });
  },
  saveFilesAndGotoStep: (currentStep, way) => {
    dispatch({ type: 'saveFilesAndGotoStep', currentStep, way });
  },
  nextStep: (event) => {
    dispatch({
      type: 'nextStep',
    });
  },
  completeForm: (event) => {
    dispatch({
      type: 'completeForm',
    });
  },
  checkForErrors: (event) => {
    const domElement = event.target;
    const errors = Utils.FieldController[`${domElement.tagName}_${domElement.type.replace('-', '')}`](
      'errors',
      domElement
    );
    if (errors) {
      dispatch({
        type: 'riseError',
        errors,
      });
    }
  },
  validateClaimStep1: async (STEP, useless, props) => {
    if (typeof props.Form.dataSent === 'undefined') {
      console.warn('No dataSent variable is set on state.Form !');
      props.Form.dataSent = false;
    }
    if (props.Form.dataSent === false) {
      dispatch({
        type: 'stuckNavigation',
      });
      dispatch({
        type: 'loadingNavigation',
      });
      props.Form.dataSent = true;
      const body = props.Form.data;
      const url = `${window.Config.APIEndpoint}/claim`;
      body.driverStarRating = window.Config.Driver.rating;
      Object.keys(body).forEach((key) => {
        body[key] = checkBooleanValue(body[key]);
      });
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
      try {
        const f = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers,
        });
        if (f.status >= 400) {
          const errorContent = await f.json();
          dispatch({
            type: 'networkError',
            error: {
              ...errorContent,
              error: 'ok',
              statusCode: f.status,
              statusText: f.statusText,
            },
          });
          return false;
        }
        const data = await f.json();
        dispatch({
          type: 'unStuckNavigation',
        });
        props.Form.dataSent = false;
        window.Config.claim = data;
        dispatch({
          type: 'SubmitedClaim',
          data,
        });
      } catch (error) {
        dispatch({
          type: 'unStuckNavigation',
        });
        let returnedError = {};
        if (error.response) {
          returnedError = {
            message: error.response.data.message,
            code: error.response.status,
          };
        } else if (error.request) {
          returnedError = {
            message: 'Unknown Error',
            code: 500,
          };
        } else {
          returnedError = {
            message: error.message,
            code: error.statusCode,
          };
        }
        props.Form.dataSent = false;
        dispatch({
          type: 'ErrorOnSubmitedClaim',
          ...returnedError,
        });
      }
    }
  },
  validateClaimStep2: async (STEP, useless, props) => {
    if (props.navigationStuck === true) {
      return false;
    }
    dispatch({
      type: 'stuckNavigation',
    });
    dispatch({
      type: 'loadingNavigation',
    });
    const { existingErrors, errors } = validateForm(props);
    if (existingErrors === true) {
      dispatch({
        type: 'unStuckNavigation',
      });
      dispatch({
        type: 'sendErrors',
        errors,
      });
    } else {
      const paymentRecipient = gatherData(props);
      let dataToSend = {
        claimId: props.claim.claimId,
        driverToRepairIndicator: checkBooleanValue(props.data.driverToRepairIndicator),
        driverDeclaredLoyaltyLevel: props.data.driverDeclaredLoyaltyLevel,
        paymentRecipient,
      };

      if (typeof props.data.totalLossIndicator !== 'undefined') {
        dataToSend.totalLossIndicator = checkBooleanValue(props.data.totalLossIndicator);
      }

      /** START TEMP */
      if (typeof props.data.startedRepairIndicator !== 'undefined') {
        dataToSend.startedRepairIndicator = checkBooleanValue(props.data.startedRepairIndicator);
      }
      dataToSend.paymentRecipient.recipientBirthDate = moment(dataToSend.paymentRecipient.recipientBirthDate).format(
        'YYYY-MM-DD'
      );

      const country = Common.isoCountryCode.filter((country) => {
        return country.value === dataToSend.paymentRecipient.recipientCountry;
      });
      dataToSend.paymentRecipient.recipientCountry = {
        code: country[0].value,
        name: country[0].label,
      };
      dataToSend.paymentRecipient.recipientAccountNumber = String(dataToSend.paymentRecipient.recipientAccountNumber);
      dataToSend.paymentRecipient.recipientSortCode = dataToSend.paymentRecipient.recipientSortCode
        .replace(/-/g, '')
        .toString();
      /** END TEMP */

      /** dates */
      if (props.data.repairStartDate) {
        dataToSend.repairStartDate = moment(props.data.repairStartDate).format('YYYY-MM-DD');
      }
      if (props.data.repairEndDate) {
        dataToSend.repairEndDate = moment(props.data.repairEndDate).format('YYYY-MM-DD');
      }
      if (props.data.vehicleReturnToPlatformDate) {
        dataToSend.vehicleReturnToPlatformDate = moment(props.data.vehicleReturnToPlatformDate).format('YYYY-MM-DD');
      }
      if (props.data.vehicleUnavailabilityStartDate) {
        dataToSend.vehicleUnavailabilityStartDate = moment(props.data.vehicleUnavailabilityStartDate).format(
          'YYYY-MM-DD'
        );
      }

      if (props.data.vehicleUnavailabilityStartDate) {
        dataToSend.vehicleUnavailabilityStartDate = moment(props.data.vehicleUnavailabilityStartDate).format(
          'YYYY-MM-DD'
        );
      }
      if (props.data.vehicleUnavailabilityStartDate) {
        dataToSend.vehicleUnavailabilityStartDate = moment(props.data.vehicleUnavailabilityStartDate).format(
          'YYYY-MM-DD'
        );
      }
      /** third party */
      if (typeof props.data.thirdPartyVehiclePlateNumber !== 'undefined') {
        dataToSend = {
          ...dataToSend,
          thirdPartyVehiclePlateNumber: props.data.thirdPartyVehiclePlateNumber,
          thirdPartyDriverFirstName: props.data.thirdPartyDriverFirstName,
          thirdPartyDriverLastName: props.data.thirdPartyDriverLastName,
          thirdPartyDriverPhoneNumber: props.data.thirdPartyDriverPhoneNumber,
        };
      }

      // Loading files
      let filesFetchs = [];
      if (window.Config.filesToUpload.damagedVehiclePictures) {
        // Loop sur les files
        filesFetchs = [...filesFetchs, ...getSignedUrlFromAWS('damagedVehiclePictures', dataToSend.claimId)];
      }
      if (window.Config.filesToUpload.incidentScenePictures) {
        // Loop sur les files
        filesFetchs = [...filesFetchs, ...getSignedUrlFromAWS('incidentScenePictures', dataToSend.claimId)];
      }
      if (window.Config.filesToUpload.vehicleReplacementProof) {
        // Loop sur les files
        filesFetchs = [...filesFetchs, ...getSignedUrlFromAWS('vehicleReplacementProof', dataToSend.claimId)];
      }
      if (window.Config.filesToUpload.vehicleRepairProof) {
        // Loop sur les files
        filesFetchs = [...filesFetchs, ...getSignedUrlFromAWS('vehicleRepairProof', dataToSend.claimId)];
      }
      if (window.Config.filesToUpload.totalLossProof) {
        // Loop sur les files
        filesFetchs = [...filesFetchs, ...getSignedUrlFromAWS('totalLossProof', dataToSend.claimId)];
      }

      const urls = await Promise.all(filesFetchs);

      const results = await Promise.all(
        urls.map((url) => {
          const [fieldName, responseObject] = url;
          const document = {
            documentIndex: Number(responseObject.index),
            documentId: responseObject.id,
          };
          if (!dataToSend[fieldName]) dataToSend[fieldName] = [];
          dataToSend[fieldName] = [...dataToSend[fieldName], document];
          const file = find(window.Config.filesToUpload[fieldName], 'filename', encodeURIComponent(responseObject.key));
          if (file) {
            const body = file.resource;
            const request = new Request(responseObject.signedUrl, {
              method: 'PUT',
              body,
            });
            return fetch(request)
              .then(async (f) => {
                if (f.status >= 400) {
                  const errorContent = await f.json();
                  dispatch({
                    type: 'networkError',
                    error: {
                      ...errorContent,
                      error: 'ok',
                      statusCode: f.status,
                      statusText: f.statusText,
                    },
                  });
                  return false;
                }
                return f;
              })
              .catch((e) => e);
          } else {
            throw Error(`No file found on window.Config.filesToUpload[${fieldName}] with file ${responseObject.key}`);
          }
        })
      );
      const payload = JSON.stringify(dataToSend);
      if (results) {
        sendClaimComplete(payload, dispatch);
        dispatch({
          type: 'concludeCustomerJourney',
        });
      }
    }
  },
});

async function sendClaimComplete(body, dispatch) {
  const url = `${window.Config.APIEndpoint}/claim/complete`;
  // Starting to collect evidences about a potential error
  let errorDetail = {
    in: 'Components/Navigation/Navigation',
    claimId: JSON.parse(body).claimId
  };
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${window.Config.OauthToken}`);
  try {
    const f = await fetch(url, {
      method: 'POST',
      body,
      headers,
    });
    const apiResponse = await f.json();
    errorDetail = {
      ...errorDetail,
      apiResponse,
      statusCode: f.status
    };
    if (f.status >= 400) {
      errorDetail.log = 'Sending the claim for completion failed.'
      sendErrorToBackEnd(errorDetail);
      dispatch({
        type: 'networkError',
        error: {
          ...apiResponse,
          error: 'ok',
          statusCode: f.status,
          statusText: f.statusText,
        },
      });
      return false;
    }
    dispatch({
      type: 'unStuckNavigation',
    });
    if (apiResponse.code && apiResponse.message) {
      errorDetail.log = 'Sending the claim for completion failed and the driver is getting a new APIError'
      sendErrorToBackEnd(errorDetail);
      throw new APIError(apiResponse.code, apiResponse.message);
    }
    window.Config.claim = apiResponse;
    dispatch({
      type: 'ClaimComplete',
      data: apiResponse,
    });
  } catch (error) {
    Object.assign(errorDetail, { error, log: 'The API claim complete thrown. Timeout?' });
    sendErrorToBackEnd(errorDetail);
    dispatch({
      type: 'unStuckNavigation',
    });
    let returnedError = {};
    if (error.response) {
      returnedError = {
        message: error.response.data.message,
        code: error.response.status,
      };
    } else if (error.request) {
      returnedError = {
        message: 'Unknown Error',
        code: 500,
      };
    } else {
      returnedError = {
        message: error.message,
        code: error.statusCode,
      };
    }
    dispatch({
      type: 'ErrorOnSubmitedClaim',
      ...returnedError,
    });
  }
}

function find(array, key, value) {
  return array.find((e) => e[key] === value);
}

function getSignedUrlFromAWS(domName, claimId) {
  // Starting to collect evidences about a potential error
  const error = new Error();
  let errorDetail = {
    claimId: claimId,
    trace: error.stack.split('\n').slice(1, 2),
    files: []
  };
  const fetchArray = [...window.Config.filesToUpload[domName]].map(async (file, index) => {
    //créer le fetch en fonction du file
    const headers = new Headers();
    headers.append('Authorization', window.Config.OauthToken);
    const qs = [`folder=${domName}`, `key=${file.filename}`, `claimid=${claimId}`, `index=${index}`];
    const url = `${window.Config.UploadEndpoint}?${qs.join('&')}`;
    const options = {
      method: 'POST',
      headers,
    };
    try {
      errorDetail.files[index] = {
        ...file,
        domName
      }
      const f = await fetch(url, options);
      const apiResponse = await f.json();
      if (f.status >= 400) {
        errorDetail = {
          ...errorDetail,
          log: 'Getting signed Url from AWS returned an error',
          status: f.status,
          apiResponse
        };
        sendErrorToBackEnd(errorDetail);
        return {
          ...apiResponse,
          error: 'ok',
          statusCode: f.status,
          statusText: f.statusText,
        };
      }
      return [domName, apiResponse];
    } catch (error) {
      errorDetail = {
        ...errorDetail,
        error,
        log: 'Getting signed Url from AWS thrown'
      }
      sendErrorToBackEnd(errorDetail);
    }
  });
  return fetchArray;
}

function checkBooleanValue(value) {
  if (value === 'false' || value === 'true') {
    if (value === 'true') value = true;
    if (value === 'false') value = false;
  }
  return value;
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import React from 'react';

import '../../Assets/css/Spinner.scss';

function Spinner() {
  const spinnerBlades = [...Array(12).keys()];
  return (
    <div className='spinner'>
      {spinnerBlades.map(i => (
        <div className='spinner-blade' key={i} />
      ))}
    </div>
  );
}

export default Spinner;
